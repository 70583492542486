<template>
  <div
    class="woot-widget-holder"
    :class="[{ 'woot-widget-holder-hide': !getUiFlags.isShowChannels }, getAppearance.icons, animationDirection]"
  >
    <a
      v-for="channel in getAvailableChannels"
      :class="getCssClass(channel)"
      :key="channel.type"
      :style="{ backgroundColor: channelsProperty[channel.type].color }"
      :href="channel.url"
      target="_blank"
      rel="noopener noreferrer"
      :title="channel.hint ? channel.hint : channel.type"
    >
      <font-awesome-icon v-if="channel.type === 'chat'" @click="openChat" icon="fa-regular fa-message fa-2xl" />
      <font-awesome-icon v-else :icon="channelsProperty[channel.type].icon" />
    </a>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { IMAGE_SIZE, AVAILABLE_IMAGE_SIZE } from '../constants/image-size';
import { useAppConfigStore } from '@/stores/appConfig';
export default {
  name: 'ChannelsView',
  props: {
    animationDirection: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      channelsProperty: {
        chat: {
          name: 'chat',
          icon: 'fa-regular fa-message fa-2xl',
          color: '#191919',
        },
        telegram: {
          name: 'telegram',
          icon: 'fa-brands  fa-telegram',
          color: '#42b5e6',
        },
        viber: {
          name: 'viber',
          icon: 'fa-brands fa-viber fa-2xl',
          color: '#59267c',
        },
        messenger: {
          name: 'messenger',
          icon: 'fa-brands  fa-facebook-messenger fa-2xl',
          color: '#3b81c2',
        },
        twitter: {
          name: 'twitter',
          icon: 'fa-brands  fa-twitter fa-2xl',
          color: '#42b5e6',
        },
        facebook: {
          name: 'facebook',
          icon: 'fa-brands  fa-facebook fa-2xl',
          color: '#3b81c2',
        },
        instagram: {
          name: 'instagram',
          icon: 'fa-brands  fa-instagram fa-2xl',
        },
        whatsapp: {
          name: 'whatsapp',
          icon: 'fa-brands fa-whatsapp fa-2xl',
          color: '#228B22',
        },
        line: {
          name: 'line',
          icon: 'fa-brands fa-line fa-2xl',
          color: '#1cad36',
        },
        email: {
          name: 'email',
          icon: 'fa-regular fa-envelope fa-2xl',
          color: '#191919',
        },
      },
    };
  },
  computed: {
    ...mapState(useAppConfigStore, {
      getUiFlags: (state) => state.getUiFlags,
      imageSize: (state) => state.getImageSize,
      getChannels: (state) => state.getChannels,
      getAppearance: (state) => state.getWidgetAppearance,
    }),
    getAvailableChannels() {
      return this.getChannels.filter((channel) => channel.enabled !== false);
    },
    getSizeIcons() {
      return AVAILABLE_IMAGE_SIZE.includes(this.imageSize) ? this.imageSize : IMAGE_SIZE.Medium;
    },
  },
  methods: {
    ...mapActions(useAppConfigStore, ['toggleUiFlags']),
    openChat() {
      this.toggleUiFlags({ isShowChat: true });
      this.toggleUiFlags({ isShowChannels: false });
    },
    getCssClass(channel) {
      const { type } = channel;
      const commonClass = `share-network-${channel.type} ${this.getSizeIcons} `;

      switch (type) {
        case 'instagram':
          return commonClass.concat('instagram');
        default:
          return commonClass;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';

#nova-talks-app {
  .woot-widget-holder {
    background-color: transparent !important;
    bottom: 80px !important;
    opacity: 1 !important;
    will-change: transform, opacity !important;
    transform: translateY(0) translateX(0) !important;
    transition: opacity 0.2s linear, transform 0.25s linear !important;
    width: 50px !important;
    display: flex !important;
    flex-direction: column;
    gap: 10px;

    &.horizontal {
      display: flex !important;
      flex-direction: row;
      margin-left: 10px !important;
      margin-right: 10px !important;
      width: auto !important;
    }
  }

  .woot-widget-holder-hide {
    transform: translateY(40px) !important;
    top: unset !important;
    opacity: 0 !important;
    visibility: hidden !important;
    height: 0 !important;
    &.right {
      transform: translateX(-40px) !important;
    }
    &.left {
      transform: translateX(40px) !important;
    }
    &.upward {
      transform: translateY(40px) !important;
    }
    &.downward {
      transform: translateY(-40px) !important;
    }
  }

  .share-network-list {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    max-width: 1000px !important;
    margin: auto !important;
  }

  a[class^='share-network-'] {
    flex: none !important;
    color: $color-background-light !important;
    border-radius: 30px !important;
    overflow: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    align-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    justify-content: center !important;
  }

  a[class^='share-network-']:hover {
    font-size: 2.2rem !important;
  }

  .instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
}
</style>
