import { default as en } from './locale/en.json';
import { default as ru } from './locale/ru.json';
import { default as uk } from './locale/uk.json';
import { default as az } from './locale/az.json';
import { default as es } from './locale/es.json';

export default {
  en,
  ru,
  uk,
  az,
  es,
};
