import { MESSAGE_STATUS, MESSAGE_TYPE } from '@/constants/messages';
import { useAppConfigStore } from '@/stores/appConfig';
import { useConversationStore } from '@/stores/conversation';

export class BaseActionCableConnector {
  constructor(app, pubsubToken, accountId) {
    const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const url = new URL(window.novaTalks.baseUrl || window.location.origin);
    const socket = new WebSocket(`${wsProtocol}://${url.host}/ws`);
    this.consumer = socket;
    this.consumer.onopen = () => {
      socket.send(
        JSON.stringify({
          event: 'subscribe',
          data: {
            pubsub_token: pubsubToken,
            account_id: accountId,
          },
        })
      );
    };
    this.consumer.onmessage = (message) => {
      const data = JSON.parse(message.data);
      this.onReceived(data);
    };
    this.consumer.onclose = (e) => {
      clearInterval(this.timerId);
      if (!window.novaTalks.manuallyClosedWs) {
        console.log(`Socket is closed. Reconnect will be attempted in 1 second.`, e);
        setTimeout(() => {
          window.novaTalks.actionCable = new BaseActionCableConnector(app, pubsubToken, accountId);
        }, 1000);
      }
    };
    this.app = app;
    this.events = {
      'message.created': this.onMessageCreated,
      'message.updated': this.onMessageUpdated,
      'presence.update': this.onPresenceUpdate,
      confirm_subscription: this.onConfirmSubscription,
    };
    this.isAValidEvent = () => true;
    this.confirmSubscription = false;
  }

  disconnect() {
    this.consumer.close();
  }

  onReceived = ({ event, data } = {}) => {
    if (this.isAValidEvent(data)) {
      if (this.events[event] && typeof this.events[event] === 'function') {
        this.events[event](data);
      }
    }
  };

  onMessageCreated = (data) => {
    const appConfig = useAppConfigStore();
    const conversation = useConversationStore();
    const getUiFlags = appConfig.getUiFlags;
    conversation.addOrUpdateMessage(data);

    if (data.sender.type !== 'contact' && (!getUiFlags.isBobbleOpen || getUiFlags.isShowChannels)) {
      appConfig.toggleUiFlags({
        showUnreadMessage: true,
      });
    }

    if (data.message_type === MESSAGE_TYPE.OUTGOING && data.status === MESSAGE_STATUS.SENT) {
      conversation.updateMessageDelivered({ id: data.id });
    }
  };

  onMessageUpdated = (data) => {
    const conversation = useConversationStore();
    conversation.addOrUpdateMessage(data);
  };

  onConfirmSubscription = (data) => {
    const conversation = useConversationStore();
    this.confirmSubscription = data === 'confirmed';
    if (window.novaTalks.wsFirstConnection) {
      conversation.fetchMissingMessages();
    }
    window.novaTalks.wsFirstConnection = true;
  };

  onPresenceUpdate = () => {
    this.consumer.send(JSON.stringify({ event: 'update_presence' }));
    const conversation = useConversationStore();
    conversation.updateMessagesStatus();
  };
}
