import { MESSAGE_TYPE } from '@/constants/messages';
import { isASubmittedFormMessage } from '@/helpers/messageTypeHelper';
import getUuid from '@/helpers/uuid';

export const createTemporaryMessage = ({ attachments, content }) => {
  const timestamp = new Date().getTime() / 1000;
  return {
    id: getUuid(),
    content,
    attachments,
    status: 'in_progress',
    created_at: timestamp,
    message_type: MESSAGE_TYPE.INCOMING,
  };
};

const getSenderName = (message) => (message.sender ? message.sender.name : '');

const shouldShowAvatar = (message, nextMessage) => {
  const currentSender = getSenderName(message);
  const nextSender = getSenderName(nextMessage);

  return (
    currentSender !== nextSender ||
    message.message_type !== nextMessage.message_type ||
    isASubmittedFormMessage(nextMessage)
  );
};

export const groupConversationBySender = (conversationsForADate) =>
  conversationsForADate.map((message, index) => {
    let showAvatar = false;
    const isLastMessage = index === conversationsForADate.length - 1;
    if (isASubmittedFormMessage(message)) {
      showAvatar = false;
    } else if (isLastMessage) {
      showAvatar = true;
    } else {
      const nextMessage = conversationsForADate[index + 1];
      showAvatar = shouldShowAvatar(message, nextMessage);
    }
    return { showAvatar, ...message };
  });

export const findUndeliveredMessage = (messageInbox, { content }) =>
  Object.values(messageInbox).filter((message) => message.content === content && message.status === 'in_progress');

export const getNonDeletedMessages = ({ messages }) => {
  return messages.filter((item) => !(item.content_attributes && item.content_attributes.deleted));
};

export const pushMessageToConversation = (store, message) => {
  const { id, status, message_type: type } = message;

  const messagesInbox = store.conversations;
  const isMessageIncoming = type === MESSAGE_TYPE.INCOMING;
  const isTemporaryMessage = status === 'in_progress';

  if (!isMessageIncoming || isTemporaryMessage) {
    messagesInbox[id] = message;
    return;
  }

  const [messageInConversation] = findUndeliveredMessage(messagesInbox, message);
  if (!messageInConversation) {
    messagesInbox[id] = message;
  } else {
    delete messagesInbox[messageInConversation.id];
    messagesInbox[id] = message;
  }
};

export const updateMessageMeta = (store, { id, meta }) => {
  const message = store.conversations[id];
  if (!message) return;

  const newMeta = message.meta ? { ...message.meta, ...meta } : { ...meta };
  message['meta'] = {
    ...newMeta,
  };
};

export const deleteMessage = (store, id) => {
  const messagesInbox = store.conversations;
  delete messagesInbox[id];
};

export const updateAttachmentMessageStatus = (store, { message, tempId }) => {
  const { id } = message;
  const messagesInbox = store.conversations;

  const messageInConversation = messagesInbox[tempId];

  if (messageInConversation) {
    delete messagesInbox[tempId];
    messagesInbox[id] = { ...message };
  }
};

export const setConversationListLoading = (store, status) => {
  store.uiFlags.isFetchingList = status;
};

export const setMessagesInConversation = (store, payload) => {
  if (!payload.length) {
    store.uiFlags.allMessagesLoaded = true;
    return;
  }

  payload.map((message) => {
    store.conversations[message.id] = message;
  });
};

export const setConversationUIFlag = (store, uiFlags) => {
  store.uiFlags = {
    ...store.uiFlags,
    ...uiFlags,
  };
};
