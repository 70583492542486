import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import parseISO from 'date-fns/parseISO';
import { getTimezoneOffset } from 'date-fns-tz';
// polyfill
require('date-time-format-timezone');

export const formatUnixDate = (date, dateFormat = 'MMM dd, yyyy') => {
  const unixDate = fromUnixTime(date);
  return format(unixDate, dateFormat);
};

export const formatDate = ({ date, todayText, yesterdayText }) => {
  const dateValue = new Date(date);
  if (isToday(dateValue)) return todayText;
  if (isYesterday(dateValue)) return yesterdayText;
  return date;
};

export const formatDigitToString = (val) => {
  return val > 9 ? `${val}` : `0${val}`;
};

export const buildDateFromTime = (hr, min, utcOffset, date = new Date()) => {
  const today = format(date, 'yyyy-MM-dd');
  const hour = formatDigitToString(hr);
  const minute = formatDigitToString(min);
  const timeString = `${today}T${hour}:${minute}:00${utcOffset}`;
  return parseISO(timeString);
};

export function subTimezoneOffset(timestamp, timezone) {
  const offset = getTimezoneOffset(timezone, fromUnixTime(timestamp)) / 1000;
  const converted = timestamp - offset;
  return converted;
}

export function addTimezoneOffset(timestamp, timezone) {
  const offset = getTimezoneOffset(timezone, fromUnixTime(timestamp)) / 1000;
  const converted = timestamp + offset;
  return converted;
}

export function addLocalTimeOffset(timestamp) {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offset = getTimezoneOffset(localTimezone, fromUnixTime(timestamp)) / 1000;
  const converted = timestamp + offset;
  return converted;
}

export function subLocalTimeOffset(timestamp) {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offset = getTimezoneOffset(localTimezone, fromUnixTime(timestamp)) / 1000;
  const converted = timestamp - offset;
  return converted;
}

/**
 * Shift unix timestamp (of UTC date) to another timestamp (of Zoned date), considering local machine's timezone
 * @param {Number} timestamp unix timestamp
 * @param {String} timezone IANA timezone
 * @returns offset unix timestamp
 */
export function unixUTCToZoned(timestamp, timezone) {
  const converted = subLocalTimeOffset(addTimezoneOffset(timestamp, timezone));
  return converted;
}

/**
 * Shift unix timestamp (of Zoned date) to another timestamp (of UTC date), considering local machine's timezone
 * @param {Number} timestamp unix timestamp
 * @param {String} timezone IANA timezone
 * @returns offset unix timestamp
 */
export function unixZonedToUTC(timestamp, timezone) {
  const converted = subTimezoneOffset(addLocalTimeOffset(timestamp), timezone);
  return converted;
}
