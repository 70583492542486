<template>
  <div class="tw-novatalks-flex tw-novatalks-items-end">
    <fluent-icon :icon="statusIcon" class="status-icon tw-novatalks-mr-1" :class="{ seen: isSeen }" />
    <div
      v-dompurify-html="formatMessage(message, false)"
      class="chat-bubble user"
      :style="{ background: widgetColor }"
    />
  </div>
</template>

<script>
import messageFormatterMixin from '@/mixins/messageFormatterMixin';
import FluentIcon from '@/components/common/FluentIcon/Index.vue';
import { MESSAGE_STATUS } from '@/constants/messages';

export default {
  name: 'UserMessageBubble',
  components: { FluentIcon },
  mixins: [messageFormatterMixin],
  props: {
    message: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    widgetColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusIcon() {
      switch (this.status) {
        case MESSAGE_STATUS.SENT:
          return 'checkmark';
        case MESSAGE_STATUS.DELIVERED:
          return 'checkmark-double';
        case MESSAGE_STATUS.SEEN:
          return 'checkmark-double';
        case MESSAGE_STATUS.PROGRESS:
          return 'send-clock';
        case MESSAGE_STATUS.FAILED:
          return 'warning';
        default:
          return 'send-clock';
      }
    },
    isSeen() {
      return this.status === MESSAGE_STATUS.SEEN;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .chat-bubble.user:deep pre {
    background: $color-primary-light;
    color: $color-body;
    overflow: scroll;
    padding: $space-smaller;
  }

  .status-icon {
    color: $color-light-gray;
  }

  .seen {
    color: $color-primary;
  }
}
</style>
