<template>
  <div id="nova-talks-app"><home-view /></div>
</template>

<script>
import HomeView from '@/views/HomeView.vue';

export default {
  name: 'App',
  components: {
    HomeView,
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/nova-talks.scss';
</style>
