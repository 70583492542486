<template>
  <unread-message-list :messages="messages" />
</template>

<script>
import { mapState } from 'pinia';
import { useConversationStore } from '@/stores/conversation';
import UnreadMessageList from '@/components/unread/UnreadMessageList.vue';

export default {
  name: 'UnreadMessages',
  components: {
    UnreadMessageList,
  },
  computed: {
    ...mapState(useConversationStore, {
      messages: (state) => state.getUnreadTextMessages,
    }),
  },
};
</script>
