<template>
  <div
    class="widget-file tw-novatalks-flex tw-novatalks-flex-row tw-novatalks-items-center tw-novatalks-p-3 widet-cursor-pointer"
  >
    <div class="widget-icon-wrap">
      <fluent-icon icon="document" size="28" />
    </div>
    <div class="widget-icon-meta">
      <div class="widget-icon-title">
        {{ title }}
      </div>
      <div class="widget-icon-link-wrap tw-novatalks-mb-1">
        <a class="widget-icon-download" rel="noreferrer noopener nofollow" target="_blank" :href="url">
          {{ $t('COMPONENTS.FILE_BUBBLE.DOWNLOAD') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FluentIcon from '@/components/common/FluentIcon/Index.vue';

export default {
  name: 'FileBubble',
  components: {
    FluentIcon,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    isInProgress: {
      type: Boolean,
      default: false,
    },
    widgetColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    title() {
      return this.isInProgress ? this.$t('COMPONENTS.FILE_BUBBLE.UPLOADING') : decodeURI(this.fileName);
    },
    fileName() {
      const filename = this.url.substring(this.url.lastIndexOf('/') + 1);
      return filename;
    },
  },
  methods: {
    openLink() {
      const win = window.open(this.url, '_blank');
      win.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .widget-file {
    .widget-icon-wrap {
      font-size: $font-size-mega;
      color: $color-woot;
      line-height: 1;
      margin-left: $space-smaller;
      margin-right: $space-small;
    }

    .widget-icon-title {
      font-weight: $font-weight-medium;
      font-size: $font-size-default;
      margin: 0;
    }

    .widget-icon-download {
      color: $color-woot;
      font-weight: $font-weight-medium;
      padding: 0;
      margin: 0;
      font-size: $font-size-small;
      text-decoration: none;
    }

    .widget-icon-link-wrap {
      line-height: 1;
    }
    .widget-icon-meta {
      padding-right: $space-smaller;
    }
  }
}
</style>
