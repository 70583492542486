<template>
  <div
    class="widget-chat-message--input is-focused"
    :class="$dm('tw-novatalks-bg-white ', 'dark:tw-novatalks-bg-slate-600')"
    @keydown.esc="hideEmojiPicker"
  >
    <resizable-text-area
      id="chat-input"
      ref="chatInput"
      v-model="userInput"
      :aria-label="$t('CHAT_PLACEHOLDER')"
      :placeholder="$t('CHAT_PLACEHOLDER')"
      class="widget-form-input widget-user-message-input is-focused"
      :class="inputColor"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div class="widget-button-wrap">
      <chat-attachment-button
        v-if="showAttachment"
        :class="$dm('tw-novatalks-text-black-900', 'dark:tw-novatalks-text-slate-100')"
        :on-attach="onSendAttachment"
      />
      <button
        class="widget-icon-button tw-novatalks-flex tw-novatalks-justify-center tw-novatalks-items-center"
        aria-label="Emoji picker"
        @click="toggleEmojiPicker"
      >
        <fluent-icon icon="emoji" :class="emojiIconColor" />
      </button>
      <emoji-input
        v-if="showEmojiPicker"
        v-click-away="hideEmojiPicker"
        :on-click="emojiOnClick"
        @keydown.esc="hideEmojiPicker"
      />
      <chat-send-button v-if="showSendButton" :on-click="handleButtonClick" :color="widgetColor" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { mixin as VueClickAway } from 'vue3-click-away';
import ChatAttachmentButton from '@/components/chat/ChatAttachment.vue';
import ChatSendButton from '@/components/chat/ChatSendButton.vue';

import EmojiInput from '@/components/common/emoji/EmojiInput';
import FluentIcon from '@/components/common/FluentIcon/Index.vue';
import ResizableTextArea from '@/components/common/ResizableTextArea.vue';
import darkModeMixin from '@/mixins/darkModeMixin.js';
import { useAppConfigStore } from '@/stores/appConfig';

export default {
  name: 'ChatInputWrap',
  components: {
    ChatAttachmentButton,
    ChatSendButton,
    EmojiInput,
    FluentIcon,
    ResizableTextArea,
  },
  mixins: [VueClickAway, darkModeMixin],
  props: {
    onSendMessage: {
      type: Function,
      default: () => {},
    },
    onSendAttachment: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      userInput: '',
      showEmojiPicker: false,
      isFocused: false,
    };
  },

  computed: {
    ...mapState(useAppConfigStore, {
      widgetColor: (state) => state.getWidgetColor,
      isWidgetOpen: (state) => state.getIsWidgetOpen,
    }),
    showAttachment() {
      return this.userInput.length === 0;
    },
    showSendButton() {
      return this.userInput.length > 0;
    },
    inputColor() {
      return `${this.$dm('tw-novatalks-bg-white', 'dark:tw-novatalks-bg-slate-600')}
        ${this.$dm('tw-novatalks-text-black-900', 'dark:tw-novatalks-text-slate-50')}`;
    },
    emojiIconColor() {
      return this.showEmojiPicker
        ? `text-woot-500 ${this.$dm('tw-novatalks-text-black-900', 'dark:tw-novatalks-text-slate-100')}`
        : `${this.$dm('tw-novatalks-text-black-900', 'dark:tw-novatalks-text-slate-100')}`;
    },
  },
  watch: {
    isWidgetOpen(isWidgetOpen) {
      if (isWidgetOpen) {
        this.focusInput();
      }
    },
  },
  unmounted() {
    document.removeEventListener('keypress', this.handleEnterKeyPress);
  },
  mounted() {
    document.addEventListener('keypress', this.handleEnterKeyPress);
    if (this.isWidgetOpen) {
      this.focusInput();
    }
  },

  methods: {
    onBlur() {
      this.isFocused = false;
    },
    onFocus() {
      this.isFocused = true;
    },
    handleButtonClick() {
      if (this.userInput && this.userInput.trim()) {
        this.onSendMessage(this.userInput);
      }
      this.userInput = '';
      this.focusInput();
    },
    handleEnterKeyPress(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.handleButtonClick();
      }
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker(e) {
      if (this.showEmojiPicker) {
        e.stopPropagation();
        this.toggleEmojiPicker();
      }
    },
    emojiOnClick(emoji) {
      this.userInput = `${this.userInput}${emoji} `;
    },

    focusInput() {
      this.$refs.chatInput.focus();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins.scss';

.widget-chat-message--input {
  align-items: center !important;
  display: flex !important;
  padding: 0 $space-small 0 $space-slab !important;
  border-radius: 7px !important;

  &.is-focused {
    box-shadow: 0 0 0 1px $color-woot, 0 0 2px 3px $color-primary-light !important;
  }
}

.widget-button-wrap {
  display: flex !important;
  align-items: center !important;
  padding-left: $space-small !important;
}

.widget-user-message-input {
  border: 0 !important;
  min-height: $space-large !important;
  max-height: 3 * $space-large !important;
  resize: none !important;
  padding: 0 !important;
  padding-top: $space-smaller !important;
  padding-bottom: $space-smaller !important;
  margin-top: $space-small !important;
  margin-bottom: $space-small !important;
}
</style>
