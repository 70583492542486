<template>
  <div class="widget-unread-wrap">
    <div class="widget-close-unread-wrap">
      <button
        class="button small widget-close-unread-button"
        @click="closeFullView"
        v-tooltip="$t('UNREAD_VIEW.CLOSE_MESSAGES_BUTTON')"
      >
          <fluent-icon icon="dismiss" size="12" />
      </button>
    </div>
    <div class="widget-unread-messages">
      <unread-message
        v-for="(message, index) in messages"
        :key="message.id"
        :message-type="message.messageType"
        :message-id="message.id"
        :show-sender="!index"
        :sender="message.sender"
        :message="getMessageContent(message)"
        :campaign-id="message.campaignId"
      />
    </div>

    <div>
      <button v-if="unreadMessageCount" class="widget-button widget-clear-button" @click="openConversationView">
        <span class="tw-novatalks-flex tw-novatalks-items-center">
          <fluent-icon class="tw-novatalks-mr-2" size="16" icon="arrow-right" />
          {{ $t('UNREAD_VIEW.VIEW_MESSAGES_BUTTON') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppConfigStore } from '@/stores/appConfig';
import { useConversationStore } from '@/stores/conversation';

import FluentIcon from '@/components/common/FluentIcon/Index.vue';
import UnreadMessage from './UnreadMessage.vue';

export default {
  name: 'UnreadMessageList',
  components: {
    FluentIcon,
    UnreadMessage,
  },
  mixins: [],
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(useConversationStore, {
      unreadMessageCount: (state) => state.getUnreadMessageCount,
    }),
    sender() {
      const [firstMessage] = this.messages;
      return firstMessage.sender || {};
    },
  },
  methods: {
    ...mapActions(useAppConfigStore, ['toggleUiFlags']),
    openConversationView() {
      this.toggleUiFlags({
        showUnreadMessage: false,
        isShowChat: true,
        isShowChannels: false,
        isBobbleOpen: true,
      });
    },
    closeFullView() {
      this.toggleUiFlags({
        showUnreadMessage: false,
      });
    },
    getMessageContent(message) {
      const { attachments, content } = message;
      const hasAttachments = attachments && attachments.length;

      if (content) return content;

      if (hasAttachments) return `📑`;

      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#nova-talks-app {
  .widget-unread-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    max-height: 100vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16) !important;
    border-radius: 10px;
    padding: 8px;
    margin: 0 0 20px;
    background: white;
    overflow: hidden;
    .widget-close-unread-wrap{
      display: flex;
      justify-content: flex-end;
      .widget-close-unread-button {
        padding: 6px;
        margin: 0;
        background: $color-background;
        color: $color-light-gray;
        border: 0;
        font-weight: $font-weight-medium;
        font-size: $font-size-default;
        transition: all 0.3s $ease-in-cubic;
        border-radius: $space-normal;
        .tw-novatalks-items-center {
          align-items: center;
          gap: 5px;
          line-height: 17px;
        }
        &:hover {
          color: $color-body;
        }
      }
    }
    .widget-unread-messages {}
    .widget-clear-button {
      background: transparent;
      color: $color-woot;
      padding: 0 10px;
      border: 0;
      font-weight: $font-weight-bold;
      font-size: $font-size-medium;
      transition: all 0.3s $ease-in-cubic;
      width: auto;
      height: 40px;
      .tw-novatalks-items-center{
        align-items: center;
        gap: 5px;
        line-height: 17px;
      }
      &:hover {
        transform: translateX($space-smaller);
        color: $color-primary-dark;
      }
    }
  }
}
</style>
