<template>
  <div class="agent-message-wrap" :class="{ 'has-response': hasRecordedResponse || isASubmittedForm }">
    <div v-if="!isASubmittedForm" class="agent-message">
      <div class="message-wrap">
        <AgentMessageBubble
          v-if="shouldDisplayAgentMessage"
          :content-type="contentType"
          :message-content-attributes="messageContentAttributes"
          :message-id="message.id"
          :message-type="messageType"
          :message="message.content"
          :avatar-url="avatar"
        />
        <div
          v-if="hasAttachments"
          class="chat-bubble has-attachment agent"
          :class="(wrapClass, $dm('tw-novatalks-bg-white', 'dark:tw-novatalks-bg-slate-50'))"
        >
          <div v-for="attachment in message.attachments" :key="attachment.id">
            <image-bubble
              v-if="attachment.file_type === 'image' && !hasImageError"
              :url="attachment.data_url"
              :thumb="attachment.thumb_url"
              :readable-time="readableTime"
              @error="onImageLoadError"
            />
            <audio v-else-if="attachment.file_type === 'audio'" controls>
              <source :src="attachment.data_url" />
            </audio>
            <file-bubble v-else :url="attachment.data_url" />
          </div>
        </div>
        <p
          v-if="showAgentName"
          class="agent-name"
          :class="$dm('tw-novatalks-text-slate-700', 'dark:tw-novatalks-text-slate-200')"
        >
          {{ agentName }}
        </p>
      </div>
    </div>

    <UserMessage v-if="hasRecordedResponse" :message="responseMessage" />
    <div v-if="isASubmittedForm">
      <UserMessage v-for="submittedValue in submittedFormValues" :key="submittedValue.id" :message="submittedValue" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppConfigStore } from '@/stores/appConfig';
import { useConversationStore } from '@/stores/conversation';
import UserMessage from '@/components/messages/UserMessage.vue';
import AgentMessageBubble from '@/components/messages/AgentMessageBubble.vue';
import timeMixin from '@/mixins/time';
import ImageBubble from '@/components/common/ImageBubble.vue';
import FileBubble from '@/components/common/FileBubble';
import { MESSAGE_STATUS, MESSAGE_TYPE } from '@/constants/messages';
import messageMixin from '@/mixins/messageMixin';
import { isASubmittedFormMessage } from '@/helpers/messageTypeHelper.js';
import darkModeMixin from '@/mixins/darkModeMixin.js';

export default {
  name: 'AgentMessage',
  components: {
    AgentMessageBubble,
    ImageBubble,
    UserMessage,
    FileBubble,
  },
  mixins: [timeMixin, messageMixin, darkModeMixin],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasImageError: false,
    };
  },
  computed: {
    ...mapState(useAppConfigStore, {
      getUiFlags: (state) => state.getUiFlags,
      getShowAgentName: (state) => state.getShowAgentName,
      getShowAgentAvatar: (state) => state.getShowAgentAvatar,
      getAvatarFetched: (state) => state.getStatusAvatarFetched,
    }),
    shouldDisplayAgentMessage() {
      if (!this.message.content && this.message.content_type === 'text') return false;
      return true;
    },
    readableTime() {
      const { created_at: createdAt = '' } = this.message;
      return this.messageStamp(createdAt, 'LLL d yyyy, h:mm a');
    },
    messageType() {
      return this.message.message_type;
    },
    contentType() {
      const { content_type: type = '' } = this.message;
      return type;
    },
    agentName() {
      if (this.message.message_type === MESSAGE_TYPE.TEMPLATE) {
        return 'Bot';
      }
      if (this.message.sender) {
        return this.message.sender.displayName || this.message.sender.name;
      }
      return 'Bot';
    },

    avatar() {
      return this.getShowAgentAvatar ? this.getAvatar({ id: this.message.sender.id }) || '' : '';
    },
    hasRecordedResponse() {
      return (
        this.messageContentAttributes.submitted_email ||
        (this.messageContentAttributes.submitted_values && !['form', 'input_csat'].includes(this.contentType))
      );
    },
    responseMessage() {
      if (this.messageContentAttributes.submitted_email) {
        return { content: this.messageContentAttributes.submitted_email };
      }

      if (this.messageContentAttributes.submitted_values) {
        if (this.contentType === 'input_select') {
          const [selectionOption = {}] = this.messageContentAttributes.submitted_values;
          return { content: selectionOption.title || selectionOption.value };
        }
      }
      return '';
    },
    isASubmittedForm() {
      return isASubmittedFormMessage(this.message);
    },
    submittedFormValues() {
      return this.messageContentAttributes.submitted_values.map((submittedValue) => ({
        id: submittedValue.name,
        content: submittedValue.value,
      }));
    },
    wrapClass() {
      return {
        'has-text': this.shouldDisplayAgentMessage,
      };
    },
    showAgentName() {
      return this.getShowAgentName && (this.message.showAvatar || this.hasRecordedResponse);
    },
  },
  watch: {
    message() {
      this.hasImageError = false;
    },
  },
  mounted() {
    this.hasImageError = false;
    if (this.getUiFlags.isTabFocused && this.message.status !== MESSAGE_STATUS.SEEN) {
      this.pushUpdateMessagesStatus({ messages: [this.message.id] });
    }
    if (this.getShowAgentAvatar) {
      const avatar = this.getAvatar({ id: this.message.sender.id });
      if (!avatar && !this.getAvatarFetched[this.message.sender.id]) {
        this.fetchAvatar({ conversationId: this.message.conversation_id, agentId: this.message.sender.id });
      }
    }
  },
  methods: {
    ...mapActions(useAppConfigStore, ['getAvatar', 'fetchAvatar']),
    ...mapActions(useConversationStore, ['pushUpdateMessagesStatus']),
    onImageLoadError() {
      this.hasImageError = true;
    },
  },
};
</script>
