import { saveDataByKey, getDataByKey, clearDataByKey } from '@/helpers/utils';
import { setHeader, removeHeader } from '@/helpers/axios';
import { defineStore } from 'pinia';
import { useAppConfigStore } from './appConfig';

export const useContactsStore = defineStore('contacts', {
  state: () => ({
    currentUser: {},
  }),
  getters: {
    getCurrentUser(state) {
      return state.currentUser;
    },
    isNewConversation(state) {
      return state.currentUser.contact && state.currentUser.conversation ? false : true;
    },
  },
  actions: {
    setUserFromIframe() {
      try {
        if (window.novaTalks.contact) {
          const appConfig = useAppConfigStore();
          const data = window.novaTalks.contact;
          setHeader('auth-widget', data.contactInbox.pubsubToken);
          this.currentUser = { ...this.currentUser, ...data };
          appConfig.toggleUiFlags({ showPreChatForm: false }, { root: true });
        }
      } catch (error) {
        console.error('setUserFromIframe error', error);
      }
    },

    async saveUser(data) {
      try {
        if (window.self !== window.top) {
          const payload = { event: 'ntk-widget-save-contact', data };
          window.parent.postMessage(payload, '*');
        } else {
          const appConfig = useAppConfigStore();
          const websiteToken = appConfig.getWebsiteToken;
          saveDataByKey(websiteToken, data);
        }
        setHeader('auth-widget', data.contactInbox.pubsubToken);
        this.currentUser = { ...this.currentUser, ...data };
      } catch (error) {
        console.error('saveUser error', error);
      }
    },
    getUser() {
      const appConfig = useAppConfigStore();
      try {
        const websiteToken = appConfig.getWebsiteToken;
        const data = getDataByKey(websiteToken);
        if (data) {
          setHeader('auth-widget', data.contactInbox.pubsubToken);
          this.currentUser = { ...this.currentUser, ...data };
          appConfig.toggleUiFlags({ showPreChatForm: false }, { root: true });
        }
      } catch (error) {
        console.error('getUser error', error);
      }
    },
    clearUser() {
      try {
        const appConfig = useAppConfigStore();
        const websiteToken = appConfig.getWebsiteToken;
        if (window.self !== window.top) {
          const data = { event: 'ntk-widget-clear-contact', data: { websiteToken } };
          window.parent.postMessage(data, '*');
          delete window.novaTalks.contact;
        } else {
          clearDataByKey(websiteToken);
        }
        removeHeader('auth-widget');
        this.currentUser = {};
      } catch (error) {
        console.error('clearUser error', error);
      }
    },
  },
});
