<template>
  <li class="option" :class="{ 'is-selected': isSelected }" :style="{ borderColor: widgetColor }">
    <div class="option-button widget-button" @click="onClick">
      <span :style="{ color: widgetColor }">{{ action.title }}</span>
    </div>
  </li>
</template>

<script>
import { mapState } from 'pinia';
import { useAppConfigStore } from '@/stores/appConfig';

export default {
  name: 'ChatOption',
  props: {
    action: {
      type: Object,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    ...mapState(useAppConfigStore, {
      widgetColor: (state) => state.getWidgetColor,
    }),
  },
  methods: {
    onClick() {
      this.$emit('click', this.action);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .option {
    border-radius: $space-jumbo;
    border: 1px solid $color-woot;
    float: left;
    margin: $space-smaller;
    max-width: 100%;

    .option-button {
      background: transparent;
      border-radius: $space-large;
      border: 0;
      cursor: pointer;
      height: auto;
      line-height: 1.5;
      min-height: $space-two * 2;
      text-align: left;
      white-space: normal;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        vertical-align: middle;
      }

      > .icon {
        margin-right: $space-smaller;
        font-size: $font-size-medium;
      }
    }
  }
}
</style>
