<template>
  <a
    v-if="isLink"
    class="widget-action-button widget-button"
    :href="action.uri"
    target="_blank"
    rel="noopener nofollow noreferrer"
  >
    {{ action.text }}
  </a>
  <button v-else class="widget-action-button widget-button" @click="onClick">
    {{ action.text }}
  </button>
</template>

<script>
import { mapActions } from 'pinia';
import { useConversationStore } from '@/stores/conversation';

export default {
  name: 'CardButton',
  props: {
    action: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isLink() {
      return this.action.type === 'link';
    },
  },

  methods: {
    ...mapActions(useConversationStore, ['sendMessage']),
    onClick() {
      this.sendMessage({
        content: this.action.payload,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .widget-action-button {
    align-items: center;
    border-radius: $space-micro;
    display: flex;
    font-weight: $font-weight-medium;
    justify-content: center;
    margin-top: $space-smaller;
    max-height: 34px;
    padding: 0;
    width: 100%;
  }
}
</style>
