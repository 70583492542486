<template>
  <form
    class="tw-novatalks-flex tw-novatalks-flex-1 tw-novatalks-flex-col tw-novatalks-p-6 tw-novatalks-overflow-y-auto"
    @submit.prevent="onSubmit"
  >
    <form-input
      v-for="field in preChatFormOptions.fields"
      :key="field.key"
      v-model.trim="fieldModels[field.key]"
      class="tw-novatalks-mt-3 field"
      :class="{ required: field.required }"
      :label="field.name"
      :placeholder="field.name"
      :list-values="field.values"
      :type="convertInputType(field.type)"
      :textarea="field.key === 'message'"
      :error="v$.fieldModels[field.key]?.$errors[0]?.$message"
      @update:modelValue="v$.fieldModels[field.key].$touch()"
    />
    <custom-button
      class="tw-novatalks-font-medium tw-novatalks-mt-5 tw-novatalks-mb-2"
      block
      :bg-color="widgetColor"
      :text-color="textColor"
      :disabled="isCreating || v$.fieldModels.$invalid"
    >
      <spinner v-if="isCreating" class="tw-novatalks-p-0" />
      {{ $t('START_CONVERSATION') }}
    </custom-button>
    <branding />
  </form>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import CustomButton from './common/Button.vue';
import FormInput from './form/FormInput.vue';
import Spinner from './common/Spinner.vue';
import Branding from './common/Branding.vue';
import { useAppConfigStore } from '@/stores/appConfig';
import { useConversationStore } from '@/stores/conversation';
import { getContrastingTextColor } from '@/helpers/utils';
import { isPhoneE164, isValidEmail } from '@/helpers/validators';
import { getLangAttribute, getBrowserLink } from '@/helpers/conversation';
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, helpers, numeric } from '@vuelidate/validators';

export default {
  name: 'PreChatForm',
  components: {
    FormInput,
    CustomButton,
    Spinner,
    Branding,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      fieldModels: {},
      fieldErrors: {},
      isValid: false,
    };
  },
  computed: {
    ...mapState(useAppConfigStore, {
      widgetColor: (state) => state.getWidgetColor,
      preChatFormOptions: (state) => state.getPreChatFormOptions,
      isEnabledLangAttribute: (state) => state.getIsEnabledLangAttribute,
      isEnabledLinkAttribute: (state) => state.getIsEnabledLinkAttribute,
      isEnabledOSAttribute: (state) => state.getIsEnabledOSAttribute,
      isEnabledBrowserAttribute: (state) => state.getIsEnabledBrowserAttribute,
      isEnabledIpCountryAttribute: (state) => state.getIsEnabledIpCountryAttribute,
    }),
    ...mapState(useConversationStore, {
      isCreating: (state) => state.getIsCreating,
    }),
    textColor() {
      return getContrastingTextColor(this.widgetColor);
    },
    osVersion(){
      const OSList = {
        "Mac": "Mac",
        "Win": "Windows",
        "Linux": "Linux",
      };
      const hash = navigator.platform;
      return OSList[Object.keys(OSList).find(el => hash.includes(el))]
    },
    browserVersion() {
      const browserList = {
        "Edg": "Microsoft Edge",
        "MSIE": "Internet Explorer",
        "Brave": "Brave",
        "Vivaldi": "Vivaldi",
        "Chrome": "Google Chrome",
        "Safari": "Safari",
        "OPR": "Opera",
        "Firefox": "Mozilla Firefox",
      }
      const hash = navigator.userAgent;
      return browserList[Object.keys(browserList).find(el => hash.includes(el))]
    }
  },
  validations () {
    const validateRules = {
      required: helpers.withMessage(this.$t('PRE_CHAT_FORM.FIELDS.REQUIRED.ERROR'),required),
      number: helpers.withMessage(this.$t('PRE_CHAT_FORM.FIELDS.NUMBER.ERROR'),numeric),
      maxLength: helpers.withMessage(this.$t('PRE_CHAT_FORM.FIELDS.LENGTH_ERROR'),maxLength(255)),
      tel: helpers.withMessage(this.$t('PRE_CHAT_FORM.FIELDS.PHONE_NUMBER.ERROR.E164'),isPhoneE164),
      email: helpers.withMessage(this.$t('PRE_CHAT_FORM.FIELDS.EMAIL_ADDRESS.ERROR'),isValidEmail),
    }
    const fieldModelsValid = {
      fieldModels:{}
    }
    this.preChatFormOptions.fields.forEach(el => {
      const validators = {};
      validators.maxLength = validateRules.maxLength
      if(el.required){
        validators.required = validateRules.required
      }
      if(el.type === 'phone'){
        validators.isPhoneE164 = validateRules.tel
      }
      if(el.type === 'email'){
        validators.isValidEmail = validateRules.email
      }
      if(el.type === 'number'){
        validators.numeric = validateRules.number
      }
      fieldModelsValid.fieldModels[el.key] = validators
    })
    return fieldModelsValid
  },
  mounted() {
    this.fieldModels = {
      ...this.preChatFormOptions.fields.reduce((accumulator, { key }) => {
        accumulator[key] = '';
        return accumulator;
      }, {}),
    };
  },
  methods: {
    ...mapActions(useConversationStore, ['createConversation']),
    convertInputType(type) {
      try {
        const types = {
          phone: 'tel',
          number: 'text'
        };
        return types[type.toLowerCase()] || type.toLowerCase();
      } catch (e) {
        return 'text';
      }
    },
    onSubmit() {
      const payload = {};
      const customAttributes = {};
      this.preChatFormOptions.fields.forEach(({ key, type }) => {
        const value = this.fieldModels[key];
        if (key === 'name') {
          payload.name = this.fieldModels.name;
        } else if (key === 'email') {
          payload.email = this.fieldModels.email;
        } else if (key === 'phone') {
          payload.phone_number = this.fieldModels.phone;
        } else if (value) {
          if (type === 'list') {
            customAttributes[key] = value.substring(1, value.length - 1);
          } else {
            customAttributes[key] = value;
          }
        }
      });

      if (this.isEnabledBrowserAttribute) {
        customAttributes.browser = this.browserVersion
      }
      if (this.isEnabledOSAttribute) {
        customAttributes.OS = this.osVersion
      }
      customAttributes.isEnabledIpCountryAttribute = this.isEnabledIpCountryAttribute
      if (this.isEnabledLangAttribute) {
        customAttributes.language = getLangAttribute();
      }
      if (this.isEnabledLinkAttribute) {
        customAttributes.browser_link = getBrowserLink();
      }
      if (Object.keys(customAttributes).length !== 0) {
        payload.custom_attributes = customAttributes;
      }
      this.createConversation(payload);
    },
  },
};
</script>

<style scoped>
.required::after {
  content: '*';
  color: red;
  position: absolute;
  top: 5px;
  right: 10px;
}

.form,
.field {
  position: relative;
}
</style>
