<template>
  <div class="widget-date--separator">
    {{ formattedDate }}
  </div>
</template>

<script>
import { formatDate } from '@/helpers/dateHelper';
export default {
  name: 'DateSeparator',
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return formatDate({
        date: this.date,
        todayText: this.$t('TODAY'),
        yesterdayText: this.$t('YESTERDAY'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#nova-talks-app {
  .widget-date--separator {
    font-size: $font-size-default;
    color: $color-body;
    height: 50px;
    line-height: 50px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .widget-date--separator::before,
  .widget-date--separator::after {
    background-color: $color-border;
    content: '';
    height: 1px;
    position: absolute;
    top: 24px;
    width: calc((100% - 120px) / 2);
  }

  .widget-date--separator::before {
    left: 0;
  }

  .widget-date--separator::after {
    right: 0;
  }
}
</style>
