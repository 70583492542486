<template>
  <div
    class="options-message chat-bubble agent"
    :class="$dm('tw-novatalks-bg-white', 'dark:tw-novatalks-bg-slate-700')"
  >
    <div class="card-body">
      <div
        v-dompurify-html="formatMessage(title, false)"
        class="title message-content"
        :class="$dm('tw-novatalks-text-black-900', 'dark:tw-novatalks-text-slate-50')"
      />
      <ul v-if="!hideFields" class="options" :class="{ 'has-selected': !!selected }">
        <chat-option
          v-for="option in options"
          :key="option.id"
          :action="option"
          :is-selected="isSelected(option)"
          @click="onClick"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import ChatOption from '@/components/template/ChatOption.vue';
import darkModeMixin from '@/mixins/darkModeMixin.js';
import { useConversationStore } from '@/stores/conversation';
import messageFormatterMixin from '@/mixins/messageFormatterMixin';

export default {
  name: 'ChatOptions',
  components: {
    ChatOption,
  },
  mixins: [darkModeMixin, messageFormatterMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: '',
    },
    hideFields: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(useConversationStore, ['sendMessage']),
    isSelected(option) {
      return this.selected === option.id;
    },
    onClick(selectedOption) {
      this.sendMessage({
        content: selectedOption.title || selectedOption.label || selectedOption.value,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .has-selected {
    .option-button:not(.is-selected) {
      color: $color-light-gray;
      cursor: initial;
    }
  }

  .options-message {
    max-width: 17rem;
    padding: $space-small $space-normal;
    border-radius: $space-small;
    overflow: hidden;

    .title {
      font-size: $font-size-default;
      font-weight: $font-weight-normal;
      margin-top: $space-smaller;
      margin-bottom: $space-smaller;
      line-height: 1.5;
    }

    .options {
      width: 100%;

      > li {
        list-style: none;
        padding: 0;
      }
    }
  }
}
</style>
