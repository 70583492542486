<template>
  <UserMessage v-if="isUserMessage" :message="message" />
  <AgentMessage v-else :message="message" />
</template>

<script>
import AgentMessage from '@/components/messages/AgentMessage.vue';
import UserMessage from '@/components/messages/UserMessage.vue';
import { MESSAGE_TYPE } from '@/constants/messages';

export default {
  name: 'ChatMessage',
  components: {
    AgentMessage,
    UserMessage,
  },
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isUserMessage() {
      return this.message.message_type === MESSAGE_TYPE.INCOMING;
    },
  },
};
</script>

<!-- <style scoped lang="scss">
.message-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: 90%;
}
</style> -->
