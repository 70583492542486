import { mapState } from 'pinia';
import { useAppConfigStore } from '@/stores/appConfig';

export default {
  computed: {
    ...mapState(useAppConfigStore, {
      darkMode: (state) => state.darkMode,
    }),
  },
  methods: {
    $dm(light, dark) {
      if (this.darkMode === 'light') {
        return light;
      }
      return light + ' ' + dark;
    },
  },
};
