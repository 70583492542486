<template>
  <div
    v-if="!!items.length"
    class="chat-bubble agent"
    :class="$dm('tw-novatalks-bg-white', 'dark:tw-novatalks-bg-slate-700')"
  >
    <div v-for="item in items" :key="item.link" class="widget-article-item">
      <a :href="item.link" target="_blank" rel="noopener noreferrer nofollow">
        <span
          class="tw-novatalks-title tw-novatalks-flex tw-novatalks-items-center tw-novatalks-text-black-900 tw-novatalks-font-medium"
        >
          <fluent-icon
            icon="link"
            class="tw-novatalks-mr-1"
            :class="$dm('tw-novatalks-text-black-900', 'dark:tw-novatalks-text-slate-50')"
          />
          <span :class="$dm('tw-novatalks-text-slate-900', 'dark:tw-novatalks-text-slate-50')">{{ item.title }}</span>
        </span>
        <span class="description" :class="$dm('tw-novatalks-text-slate-700', 'dark:tw-novatalks-text-slate-200')">
          {{ truncateMessage(item.description) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import messageFormatterMixin from '@/mixins/messageFormatterMixin';
import FluentIcon from '../common/FluentIcon/Index.vue';
import darkModeMixin from '@/mixins/darkModeMixin.js';

export default {
  name: 'Article',
  components: {
    FluentIcon,
  },
  mixins: [messageFormatterMixin, darkModeMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .widget-article-item {
    border-bottom: 1px solid $color-border;
    font-size: $font-size-default;
    padding: $space-small 0;

    a {
      color: $color-body;
      text-decoration: none;
    }

    .description {
      display: block;
      margin-top: $space-smaller;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
