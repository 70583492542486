<template>
  <div class="chat-bubble-wrap">
    <Thumbnail v-if="avatarUrl" :src="avatarUrl" />
    <div
      v-if="!isCards && !isOptions && !isArticle && !isCards && !isCSAT"
      class="chat-bubble agent"
      :class="$dm('tw-novatalks-bg-white', 'dark:tw-novatalks-bg-slate-700')"
    >
      <div
        v-dompurify-html="formatMessage(message, false)"
        class="message-content"
        :class="$dm('tw-novatalks-text-black-900', 'dark:tw-novatalks-text-slate-50')"
      />
    </div>
    <div v-if="isOptions">
      <chat-options
        :title="message"
        :options="messageContentAttributes.items"
        :hide-fields="!!messageContentAttributes.submitted_values"
        @click="onOptionSelect"
      />
    </div>
    <div v-if="isCards">
      <chat-card
        v-for="item in messageContentAttributes.items"
        :key="item.title"
        :media-url="item.media_url"
        :title="item.title"
        :description="item.description"
        :actions="item.actions"
      />
    </div>
    <div v-if="isArticle">
      <chat-article :items="messageContentAttributes.items" />
    </div>
    <customer-satisfaction
      v-if="isCSAT"
      :message-content-attributes="messageContentAttributes.submitted_values"
      :message-id="messageId"
    />
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import ChatCard from '@/components/template/ChatCard.vue';
import ChatOptions from '@/components/template/ChatOptions.vue';
import ChatArticle from '@/components/template/Article.vue';
import CustomerSatisfaction from '@/components/template/CustomerSatisfaction';
import darkModeMixin from '@/mixins/darkModeMixin.js';
import messageFormatterMixin from '@/mixins/messageFormatterMixin';
import { MESSAGE_TYPE } from '@/constants/messages';
import Thumbnail from '../Thumbnail.vue';
import { useMessageStore } from '@/stores/message';

export default {
  name: 'AgentMessageBubble',
  components: {
    ChatArticle,
    ChatCard,
    ChatOptions,
    CustomerSatisfaction,
    Thumbnail,
  },
  mixins: [messageFormatterMixin, darkModeMixin],
  props: {
    message: { type: String, default: null },
    contentType: { type: String, default: null },
    messageType: { type: String, default: null },
    messageId: { type: Number, default: null },
    messageContentAttributes: {
      type: Object,
      default: () => {},
    },
    avatarUrl: { type: String, default: '' },
  },
  computed: {
    isTemplate() {
      return this.messageType === MESSAGE_TYPE.TEMPLATE;
    },
    isCards() {
      return this.contentType === 'cards';
    },
    isOptions() {
      return this.contentType === 'input_select';
    },
    isArticle() {
      return this.contentType === 'article';
    },
    isCSAT() {
      return this.contentType === 'input_csat';
    },
  },
  methods: {
    ...mapActions(useMessageStore, ['update']),
    onOptionSelect(selectedOption) {
      this.update({
        submittedValues: [selectedOption],
        messageId: this.messageId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .chat-bubble-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .chat-bubble {
    margin-left: $space-smaller;
  }
  .chat-bubble .message-content:deep pre {
    background: $color-primary-light;
    color: $color-body;
    overflow: scroll;
    padding: $space-smaller;
  }
}
</style>
