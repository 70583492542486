import { prefixWidgetCookies } from '@/constants/saved-data';

export const getLocalStorageByKey = (websiteToken) => {
  const key = `${prefixWidgetCookies}${websiteToken}`;
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const setLocalStorageByKey = (websiteToken, data) => {
  const key = `${prefixWidgetCookies}${websiteToken}`;
  const value = JSON.stringify(data);
  localStorage.setItem(key, value);
};

export const clearLocalStorageByKey = (websiteToken) => {
  const key = `${prefixWidgetCookies}${websiteToken}`;
  localStorage.removeItem(key);
};
