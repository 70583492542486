import { IMAGE_SIZE } from '@/constants/image-size';

import { getAvatarURL } from '@/api/avatars';
import { isMobile, getWorkingTimeStatus } from '@/helpers/utils';
import { defineStore } from 'pinia';

export const useAppConfigStore = defineStore('appConfig', {
  state: () => ({
    websiteToken: window.novaTalks.websiteToken.trim(),
    widgetAssetsURL: window.novaTalks.widgetAssetsURL || 'https://storage.novatalks.ai/static/widget/v1',
    isWidgetOpen: false,
    darkMode: 'light',
    widgetColor: window.novaTalks.widgetColor || '#009de0',
    widgetPosition: window.novaTalks.widgetPosition || 'bottom-right',
    widgetMargin: window.novaTalks.widgetMargin || '20px',
    widgetAppearance: window.novaTalks.widgetAppearance || {
      desktop: {
        show: true,
        maximized: false,
        icons: 'vertical',
      },
      mobile: {
        show: true,
        maximized: false,
        icons: 'vertical',
      },
    },
    locale: window.novaTalks.locale || 'en',
    imageSize: window.novaTalks.imageSize || IMAGE_SIZE.Medium,
    widgetHeading: window.novaTalks.widgetHeading,
    channels: window.novaTalks.channels || [],
    preChatFormOptions: {
      offline: {
        enabled: false,
        fields: [],
      },
      online: {
        enabled: false,
        fields: [],
      },
      ...window.novaTalks.preChatFormOptions,
    },
    showAgentName: window.novaTalks.showAgentName,
    showAgentAvatar: window.novaTalks.showAgentAvatar,
    bubbleImage: window.novaTalks.bubbleImage,
    uiFlags: {
      isTabFocused: true,
      isMobile: isMobile(),
      isShowWidget: true,
      isBobbleOpen: false,
      isShowChannels: false,
      isShowChat: false,
      isCreating: false,
      allMessagesLoaded: false,
      isFetchingList: false,
      showMessage: false,
      showUnreadMessage: false,
      showCloseWidgetIcon: window.novaTalks.showCloseWidgetIcon || false,
      showPreChatForm: window.novaTalks.showPreChatForm || false,
    },
    branding: {
      poweredByText: window.novaTalks.branding?.poweredByText,
      poweredByIsDisabled: window.novaTalks.branding?.poweredByIsDisabled,
      url: window.novaTalks.branding?.url || 'https://novatalks.ai',
    },
    isWidgetDynamic: window.novaTalks.isWidgetDynamic || false,
    isEnabledLangAttribute: window.novaTalks.isEnabledLangAttribute || false,
    isEnabledLinkAttribute: window.novaTalks.isEnabledLinkAttribute || false,
    isEnabledOSAttribute: window.novaTalks.isEnabledOSAttribute || false,
    isEnabledBrowserAttribute: window.novaTalks.isEnabledBrowserAttribute || false,
    isEnabledIpCountryAttribute: window.novaTalks.isEnabledIpCountryAttribute || false,
    avatars: {},
    isInIframe: window.self !== window.top,
    avatarFetched: {},
  }),
  getters: {
    getWebsiteToken: (state) => state.websiteToken,
    getWidgetHeading: (state) => state.widgetHeading,
    getUiFlags: (state) => state.uiFlags,
    getPreChatFormOptions: (state) => {
      const status = getWorkingTimeStatus(state.preChatFormOptions.calendar);
      return state.preChatFormOptions[status];
    },
    getIsWidgetOpen: (state) => state.uiFlags.isBobbleOpen,
    getWidgetColor: (state) => state.widgetColor,
    getWidgetPosition: (state) => state.widgetPosition,
    getWidgetMargin: (state) => state.widgetMargin,
    getWidgetAppearance: (state) => {
      return state.uiFlags.isMobile ? state.widgetAppearance.mobile : state.widgetAppearance.desktop;
    },
    getImageSize: (state) => state.imageSize,
    getIsCreating: (state) => state.uiFlags.isCreating,
    getIsFetchingList: (state) => state.uiFlags.isFetchingList,
    getBubbleImage: (state) => state.bubbleImage,
    getBranding: (state) => state.branding,
    getWidgetAssetsURL: (state) => state.widgetAssetsURL,
    getChannels: (state) => state.channels,
    getLocale: (state) => state.locale,
    getShowAgentName: (state) => state.showAgentName,
    getShowAgentAvatar: (state) => state.showAgentAvatar,
    getIsEnabledLangAttribute: (state) => state.isEnabledLangAttribute,
    getIsEnabledLinkAttribute: (state) => state.isEnabledLinkAttribute,
    getIsEnabledOSAttribute: (state) => state.isEnabledOSAttribute,
    getIsEnabledBrowserAttribute: (state) => state.isEnabledBrowserAttribute,
    getIsEnabledIpCountryAttribute: (state) => state.isEnabledIpCountryAttribute,
    getIsInIframe: (state) => state.isInIframe,
    getAvatar:
      (state) =>
      ({ id }) => {
        return state.avatars[id];
      },
    getStatusAvatarFetched: (state) => state.avatarFetched,
  },
  actions: {
    toggleUiFlags(uiFlags) {
      if (window.self !== window.top) {
        const flags = { ...this.uiFlags, ...uiFlags };
        const data = { event: 'ntk-widget-toggle-ui-flags', uiFlags: flags };
        window.parent.postMessage(data, '*');
      }
      this.uiFlags = { ...this.uiFlags, ...uiFlags };
    },

    async fetchAvatar({ conversationId, agentId }) {
      try {
        this.avatarFetched[agentId] = true;
        const websiteToken = this.getWebsiteToken;
        const { data } = await getAvatarURL({ websiteToken, conversationId, agentId });
        this.avatars = { ...this.avatars, [agentId]: data.avatar_url };
      } catch (e) {
        console.log(e);
      } finally {
        this.avatarFetched[agentId] = false;
      }
    },
  },
});
