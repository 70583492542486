import MessageFormatter from '../helpers/messageFormatter';
import DOMPurify from 'dompurify';

export default {
  computed: {
    isRepliedMessage() {
      if (this.message && typeof this.message === 'string') {
        return this.message.includes('[RepliedContent:]') && this.message.includes('[Content:]');
      }
      return false;
    },
  },
  methods: {
    repliedMessageContentFormatter(message) {
      const repliedContent = '> ' + message.split('[Content:]')[0].split('[RepliedContent:]')[1] + '\n\n';
      const mainContent = message.split('[Content:]')[1];
      return repliedContent + mainContent;
    },
    formatMessage(message, isATweet, isAPrivateNote) {
      if (this.isRepliedMessage && message) {
        message = this.repliedMessageContentFormatter(message);
      }
      const messageFormatter = new MessageFormatter(message, isATweet, isAPrivateNote);
      return messageFormatter.formattedMessage;
    },
    getPlainText(message, isATweet) {
      const messageFormatter = new MessageFormatter(message, isATweet);
      return messageFormatter.plainText;
    },
    truncateMessage(description = '') {
      if (description.length < 100) {
        return description;
      }

      return `${description.slice(0, 97)}...`;
    },
    stripStyleCharacters(message) {
      return DOMPurify.sanitize(message, {
        FORBID_TAGS: ['style'],
        FORBID_ATTR: [
          'id',
          'class',
          'style',
          'bgcolor',
          'valign',
          'width',
          'face',
          'color',
          'height',
          'lang',
          'align',
          'size',
          'border',
        ],
      });
    },
  },
};
