import Cookies from 'js-cookie';
import { prefixWidgetCookies } from '@/constants/saved-data';

export const getCookieByKey = (websiteToken) => {
  const key = `${prefixWidgetCookies}${websiteToken}`;
  const data = Cookies.get(key);
  return data ? JSON.parse(Cookies.get(key)) : null;
};

export const setCookieByKey = (websiteToken, data) => {
  const key = `${prefixWidgetCookies}${websiteToken}`;
  const value = JSON.stringify(data);
  const options = {
    expires: 365,
    sameSite: 'Lax',
  };
  Cookies.set(key, value, options);
};

export const clearCookieByKey = (websiteToken) => {
  const key = `${prefixWidgetCookies}${websiteToken}`;
  Cookies.remove(key);
};
