<template>
  <div
    v-if="!isDisableBranding"
    class="tw-novatalks-px-0 tw-novatalks-py-3 tw-novatalks-flex tw-novatalks-justify-center branding--wrap"
  >
    <a
      :href="widgetBrandURL"
      rel="noreferrer noopener nofollow"
      target="_blank"
      class="branding--link tw-novatalks-justify-center"
    >
      <img class="branding--image" alt="NovaTalks" :src="logoThumbnail" />
      <span>
        {{ brandingText || $t('POWERED_BY') }}
      </span>
    </a>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useAppConfigStore } from '@/stores/appConfig';

export default {
  name: 'Branding',
  computed: {
    ...mapState(useAppConfigStore, {
      branding: (state) => state.getBranding,
      widgetAssetsURL: (state) => state.getWidgetAssetsURL,
    }),
    brandingText() {
      return this.branding.poweredByText;
    },
    isDisableBranding() {
      return this.branding.poweredByIsDisabled;
    },
    widgetBrandURL() {
      return this.branding.url;
    },
    logoThumbnail() {
      return process.env.NODE_ENV === 'development'
        ? '/images/brand-assets/novait-small.png'
        : `${this.widgetAssetsURL}/images/brand-assets/novait-small.png`;
    },
  },
  methods: {
    useInstallationName(str = '', installationName) {
      return str.replace(/NovaTalks/g, installationName);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.branding--image {
  margin-right: $space-smaller;
  max-width: $space-normal;
  max-height: $space-normal;
}
.branding--link {
  color: $color-light-gray;
  cursor: pointer;
  display: inline-flex;
  filter: grayscale(1);
  font-size: $font-size-small;
  opacity: 0.9;
  text-decoration: none;
  &:hover {
    filter: grayscale(0);
    opacity: 1;
    color: $color-gray;
  }
}
</style>
