<template>
  <label class="widget-icon-button tw-novatalks-flex tw-novatalks-items-center tw-novatalks-justify-center">
    <input class="widget-input" type="file" @change="handleFileChange($event)" />
    <fluent-icon v-if="!isUploading.image" icon="attach" />
    <spinner v-if="isUploading" size="small" />
  </label>
</template>

<script>
import FluentIcon from '@/components/common/FluentIcon/Index.vue';
import Spinner from '@/components/common/Spinner.vue';
import { checkFileSizeLimit } from '@/helpers/fileHelper.js';
import { MAXIMUM_FILE_UPLOAD_SIZE, ALLOWED_FILE_TYPES } from '@/constants/messages';
import { BUS_EVENTS } from '@/constants/busEvents';

export default {
  name: 'ChatAttachment',
  components: { FluentIcon, Spinner },
  props: {
    onAttach: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isUploading: false,
      acceptedFiles: ALLOWED_FILE_TYPES.split(',')
        .map((type) => type.trim())
        .filter((type) => type !== ''),
    };
  },
  computed: {
    fileUploadSizeLimit() {
      return MAXIMUM_FILE_UPLOAD_SIZE;
    },
  },
  methods: {
    handleFileChange(e) {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        this.onIndirectFileUpload(file);
      }
    },
    getFileType(fileType) {
      return fileType.includes('image') ? 'image' : 'file';
    },
    checkAllowedFileTypes(file) {
      let isAllowed = false;
      this.acceptedFiles.forEach((type) => {
        if (!isAllowed) {
          if (type.startsWith('.')) {
            isAllowed = file.name.split('.')[1] === type.substring(1);
          } else if (type.endsWith('/*')) {
            isAllowed = file.type.split('/')[0] === type.split('/')[0];
          } else {
            isAllowed = file.type === type;
          }
        }
      });
      return isAllowed;
    },
    async onFileUpload(file) {
      this.onIndirectFileUpload(file);
    },
    async onIndirectFileUpload(file) {
      this.isUploading = true;
      try {
        if (!checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
          window.bus.$emit(BUS_EVENTS.SHOW_ALERT, {
            message: this.$t('FILE_SIZE_LIMIT', {
              MAXIMUM_FILE_UPLOAD_SIZE: this.fileUploadSizeLimit,
            }),
          });
        } else if (!this.checkAllowedFileTypes(file)) {
          window.bus.$emit(BUS_EVENTS.SHOW_ALERT, {
            message: this.$t('FILE_TYPE_DISALLOWED', {
              ALLOWED_FILE_TYPES,
            }),
          });
        } else {
          await this.onAttach({
            file,
            ...this.getLocalFileAttributes(file),
          });
        }
      } catch (error) {
        // Error
      }
      this.isUploading = false;
    },
    getLocalFileAttributes(file) {
      return {
        thumbUrl: window.URL.createObjectURL(file),
        fileType: this.getFileType(file.type),
      };
    },
  },
};
</script>

<style scoped>
.widget-icon-button {
  position: relative;
  cursor: pointer;
}
.widget-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}
</style>
