import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { domPurifyConfig } from './helpers/HTMLSanitizer';
import i18n from './i18n';
import App from './App.vue';
import { createPinia } from 'pinia';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faMessage, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowUpRightFromSquare, faXmark, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import {
  faViber,
  faTelegram,
  faWhatsapp,
  faFacebookMessenger,
  faFacebook,
  faInstagram,
  faTwitter,
  faLine,
} from '@fortawesome/free-brands-svg-icons';
import VTooltip from 'v-tooltip';

export function initApplication() {
  library.add(
    faArrowUpRightFromSquare,
    faXmark,
    faViber,
    faTelegram,
    faWhatsapp,
    faFacebookMessenger,
    faFacebook,
    faInstagram,
    faTwitter,
    faMessage,
    faWindowMinimize,
    faLine,
    faEnvelope
  );

  const app = createApp(App);
  const pinia = createPinia();

  app.component('font-awesome-icon', FontAwesomeIcon);
  app.use(VueDOMPurifyHTML, domPurifyConfig);
  app.use(pinia);
  const locale = window.novaTalks.locale || 'en';
  const i18nConfig = createI18n({
    locale,
    messages: i18n,
  });
  app.use(i18nConfig);
  app.use(VTooltip);

  window.novaTalks.bus = createApp();

  (() => {
    const initApp = () => {
      app.mount('#nova-talks');
      window.novaTalks.WIDGET = app;
      window.novaTalks.WIDGET.i18n = i18nConfig;
    };
    if (document.readyState === 'complete') initApp();
    else window.onload = () => initApp();
  })();
}
