<template>
  <div v-if="!loading">
    <div class="widget-wrap" :class="wrapClass" :style="widgetWrapStyle" v-if="getUiFlags.isShowWidget">
      <unread-messages v-if="getUiFlags.showUnreadMessage" />
      <channels-view v-else :animation-direction="animationDirection" />
      <chat-view v-if="getUiFlags.isShowChat && getUiFlags.isBobbleOpen" />
      <div class="bubble-container">
        <button
          :class="getFullBubbleImageClass"
          :style="getStyle"
          @click="open"
          role="button"
          aria-label="toggle widget"
          :aria-pressed="getUiFlags.isBobbleOpen"
          :aria-expanded="getUiFlags.isBobbleOpen"
        >
          <img v-if="!getUiFlags.isBobbleOpen" :src="getBubbleImage" alt="" />
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#FFF" viewBox="0 0 50 50"><path d="M9.156 6.313 6.312 9.155 22.157 25 6.22 40.969 9.03 43.78 25 27.844 40.938 43.78l2.843-2.843L27.844 25 43.687 9.156l-2.843-2.844L25 22.157Z"/></svg>
         </button>
        <div class="wrap-woot-widget-bubble-close" v-if="getUiFlags.showCloseWidgetIcon">
          <font-awesome-icon icon="fa-solid fa-xmark" class="woot-widget-bubble-close" @click="closeWidget" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import ChatView from '@/components/ChatView.vue';
import ChannelsView from './ChannelsView.vue';
import UnreadMessages from './UnreadMessages.vue';
import { useAppConfigStore } from '@/stores/appConfig';
import { useContactsStore } from '@/stores/contacts';
import { bubbleImg } from '@/constants/image-size';

export default {
  name: 'HomeView',
  components: {
    ChatView,
    ChannelsView,
    UnreadMessages,
  },
  data() {
    return {
      loading: true,
      hasTabFocus: !document.hidden && document.hasFocus(),
    };
  },
  computed: {
    ...mapState(useAppConfigStore, {
      getUiFlags: (state) => state.getUiFlags,
      bubbleImage: (state) => state.getBubbleImage,
      getWidgetColor: (state) => state.getWidgetColor,
      getPosition: (state) => state.getWidgetPosition,
      getMargin: (state) => state.getWidgetMargin,
      getAppearance: (state) => state.getWidgetAppearance,
      isInIframe: (state) => state.getIsInIframe,
    }),
    getBubbleImage() {
      return this.bubbleImage || bubbleImg;
    },
    getStyle() {
      if (this.getUiFlags.isBobbleOpen || !this.bubbleImage) {
        return { 'background-color': this.getWidgetColor };
      }
      return {};
    },
    getFullBubbleImageClass() {
      let cls = 'woot-widget-bubble woot-widget-bubble-color';
      if (this.bubbleImage) {
        cls += ' full-bubble-image';
      } else {
        cls += ' default-bubble-image';
      }
      return cls;
    },
    wrapClass() {
      return [this.getPosition, this.getAppearance.icons];
    },
    animationDirection() {
      if (this.getAppearance.icons === 'horizontal') {
        if (this.getPosition === 'middle-left' || this.getPosition === 'bottom-left') {
          return 'right';
        }
        if (this.getPosition === 'middle-right' || this.getPosition === 'bottom-right') {
          return 'left';
        }
      }
      if (this.getAppearance.icons === 'vertical') {
        if (this.getPosition === 'middle-left' || this.getPosition === 'middle-right') {
          return 'downward';
        }
        if (this.getPosition === 'bottom-left' || this.getPosition === 'bottom-right') {
          return 'upward';
        }
      }
      return '';
    },
    widgetWrapStyle() {
      const styles = {};
      if (this.getPosition === 'bottom-left' || this.getPosition === 'bottom-right') {
        styles.bottom = this.isInIframe ? `15px !important` : '20px !important';
      }
      if (this.getPosition === 'middle-left' || this.getPosition === 'middle-right') {
        styles.top = this.isInIframe ? `15px !important` : '50vh !important';
        if (this.getUiFlags.isShowChannels && !this.isInIframe) {
          styles.transform = 'translateY(-50%)';
        }
      }
      if (this.getPosition === 'bottom-left' || this.getPosition === 'middle-left') {
        styles.left = this.isInIframe ? `15px !important` : `${this.getMargin} !important`;
      }
      if (this.getPosition === 'bottom-right' || this.getPosition === 'middle-right') {
        styles.right = this.isInIframe ? `15px !important` : `${this.getMargin} !important`;
      }
      return styles;
    },
  },
  watch: {
    hasTabFocus: {
      handler(isFocused) {
        this.toggleUiFlags({ isTabFocused: isFocused });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(useAppConfigStore, ['toggleUiFlags', 'getWidgetSettings']),
    ...mapActions(useContactsStore, ['getUser', 'setUserFromIframe']),
    open() {
      this.toggleUiFlags({
        isBobbleOpen: !this.getUiFlags.isBobbleOpen,
        showUnreadMessage: false,
      });
      if (!this.getUiFlags.isShowChat) {
        this.toggleUiFlags({ isShowChannels: !this.getUiFlags.isShowChannels });
      }
    },
    closeWidget() {
      window.novaTalks.manuallyClosedWs = true;
      if (window.novaTalks.actionCable) {
        window.novaTalks.actionCable.disconnect();
      }
      this.toggleUiFlags({ isShowWidget: false });
    },
    gainFocus() {
      if (!this.hasTabFocus) {
        if (!this.isInIframe) {
          window.focus();
        }
        this.hasTabFocus = true;
      }
    },
    loseFocus() {
      if (this.hasTabFocus) {
        this.hasTabFocus = false;
        if (!this.isInIframe) {
          window.blur();
        }
      }
    },

    visibilitychange() {
      if (document.hidden) {
        this.loseFocus();
      } else {
        this.gainFocus();
      }
    },
  },
  mounted() {
    const css = document.createElement('style');
    css.innerHTML = `#nova-talks-app .woot-widget-bubble:hover, woot-widget-bubble:focus {\n
      box-shadow: 0 0 0 3px #fff,0 0 0 6px ${this.getWidgetColor} !important;\n
      \n}`;
    document.body.appendChild(css);
    if (this.isInIframe) {
      this.setUserFromIframe();
      window.addEventListener('ntk-widget-focus', this.gainFocus);
      window.addEventListener('ntk-widget-blur', this.loseFocus);
      document.addEventListener('ntk-widget-visibilitychange', this.visibilitychange);
    } else {
      window.addEventListener('focus', this.gainFocus);
      window.addEventListener('blur', this.loseFocus);
      document.addEventListener('visibilitychange', this.visibilitychange);
      this.getUser();
    }

    this.loading = false;

    if (!this.getAppearance.show) {
      this.toggleUiFlags({ isShowWidget: false });
      return;
    }
    if (this.getAppearance.maximized) {
      this.toggleUiFlags({ isShowChannels: true, isBobbleOpen: true });
    }
  },
  beforeDestroy() {
    if (this.isInIframe) {
      window.removeEventListener('ntk-widget-focus', this.gainFocus);
      window.removeEventListener('ntk-widget-blur', this.loseFocus);
      document.removeEventListener('ntk-widget-visibilitychange', this.visibilitychange);
    } else {
      window.removeEventListener('focus', this.gainFocus);
      window.removeEventListener('blur', this.loseFocus);
      document.removeEventListener('visibilitychange', this.visibilitychange);
    }
  },
};
</script>

<style lang="scss">
#nova-talks-app {
  .widget-wrap {
    position: fixed !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    row-gap: 15px;

    &.horizontal.bottom-right,
    &.horizontal.middle-right {
      display: flex !important;
      flex-direction: row !important;
    }

    &.horizontal.bottom-left,
    &.horizontal.middle-left {
      display: flex !important;
      flex-direction: row-reverse !important;
      justify-content: flex-end;
    }

    &.vertical.middle-left,
    &.vertical.middle-right {
      flex-direction: column-reverse !important;
    }

    &.vertical.bottom-left,
    &.vertical.middle-left {
      display: flex !important;
    }
  }

  .bubble-container {
    width: 64px;
    height: 64px;
    position: relative;
    margin: 0 0 0 auto;
  }

  .woot-widget-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px !important;
    border-width: 0px !important;
    bottom: 20px !important;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16) !important;
    cursor: pointer !important;
    height: 64px !important;
    width: 64px !important;
    user-select: none !important;
  }

  .wrap-woot-widget-bubble-close {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .wrap-woot-widget-bubble-close path {
    color: black;
  }

  .woot-widget-bubble-close {
    position: absolute;
    bottom: 0px;
    left: 0px;
    cursor: pointer !important;
  }

  .medium {
    width: 50px !important;
    height: 50px !important;
    font-size: 30px !important;
  }

  .woot-widget-bubble img {
    all: revert !important;
  }

  .default-bubble-image img {
    height: 24px !important;
    width: 24px !important;
  }

  .full-bubble-image img {
    height: 64px !important;
    width: 64px !important;
    border-radius: 50% !important;
  }

  button:focus {
    outline: none !important;
  }
}
</style>
