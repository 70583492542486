import { MESSAGE_TYPE } from '@/constants/messages';
import { groupBy } from 'lodash';
import {
  groupConversationBySender,
  createTemporaryMessage,
  getNonDeletedMessages,
  pushMessageToConversation,
  updateMessageMeta,
  deleteMessage,
  updateAttachmentMessageStatus,
  setConversationListLoading,
  setMessagesInConversation,
  setConversationUIFlag,
} from './helpers';
import { formatUnixDate } from '@/helpers/dateHelper';
import {
  createConversationAPI,
  updateConversationAPI,
  sendMessageAPI,
  getMessagesAPI,
  sendAttachmentAPI,
  getConversationAPI,
  updateMessageStatusAPI,
} from '@/api/conversation';
import { BaseActionCableConnector } from '@/helpers/actionCable.js';
import { retryWithDelay } from '@/helpers/utils';
import { defineStore } from 'pinia';
import { useContactsStore } from './contacts';
import { useAppConfigStore } from './appConfig';
import { MESSAGE_STATUS } from '@/constants/messages';
import { setHeader } from '@/helpers/axios';

export const useConversationStore = defineStore('conversation', {
  state: () => ({
    conversations: {},
    updateMessagesQueue: [],
    textAreaHeight: 32,
    meta: {
      userLastSeenAt: undefined,
    },
    uiFlags: {
      allMessagesLoaded: false,
      isFetchingList: false,
      isAgentTyping: false,
      isCreating: false,
    },
  }),

  getters: {
    getTextAreaHeight: (state) => state.textAreaHeight,
    getAllMessagesLoaded: (state) => state.uiFlags.allMessagesLoaded,
    getIsCreating: (state) => state.uiFlags.isCreating,
    getIsAgentTyping: (state) => state.uiFlags.isAgentTyping,
    getConversation: (state) => state.conversations,
    getConversationSize: (state) => Object.keys(state.conversations).length,
    getEarliestMessage: (state) => {
      const conversation = Object.values(state.conversations);
      if (conversation.length) {
        return conversation[0];
      }
      return {};
    },
    getLastMessage: (state) => {
      const conversation = Object.values(state.conversations);
      const lastIndex = conversation.length - 1;
      if (conversation.length) {
        return conversation[lastIndex];
      }
      return {};
    },
    getGroupedConversation: (state) => {
      const conversationGroupedByDate = groupBy(Object.values(state.conversations), (message) =>
        formatUnixDate(message.created_at)
      );
      return Object.keys(conversationGroupedByDate).map((date) => {
        return {
          date,
          messages: groupConversationBySender(conversationGroupedByDate[date]).sort((a, b) =>
            a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0
          ),
        };
      });
    },
    getIsFetchingList: (state) => state.uiFlags.isFetchingList,
    getMessageCount: (state) => {
      return Object.values(state.conversations).length;
    },
    getUnreadMessageCount: (state) => {
      const { userLastSeenAt } = state.meta;
      const count = Object.values(state.conversations).filter((chat) => {
        const { created_at: createdAt, message_type: messageType } = chat;
        const isOutGoing = messageType === MESSAGE_TYPE.OUTGOING;
        const hasNotSeen = userLastSeenAt ? createdAt * 1000 > userLastSeenAt * 1000 : true;
        return hasNotSeen && isOutGoing;
      }).length;
      return count;
    },
    getUnreadTextMessages: (state) => {
      const allMessages = Object.values(state.conversations);
      const unreadAgentMessages = allMessages.filter((message) => {
        return message.message_type === MESSAGE_TYPE.OUTGOING;
      });
      const unreadAgentMessageslength = unreadAgentMessages.length - 1;
      return [unreadAgentMessages[unreadAgentMessageslength]];
    },
  },

  actions: {
    updateTextAreaHeight(payload){
      this.textAreaHeight = payload;
    },
    async sendMessage(params) {
      const { content } = params;
      const message = createTemporaryMessage({ content });
      this.sendMessageWithData(message);
    },
    async sendMessageWithData(message) {
      const appConfig = useAppConfigStore();
      const contacts = useContactsStore();
      const { id, content, meta = {} } = message;
      pushMessageToConversation(this, message);
      updateMessageMeta(this, { id, meta: { ...meta, error: '' } });
      try {
        const { conversation, contact, account_id } = contacts.getCurrentUser;
        const websiteToken = appConfig.getWebsiteToken;
        const { data } = await sendMessageAPI({
          content,
          websiteToken,
          conversationId: conversation.id,
          accountId: account_id,
          contactId: contact.id,
        });
        deleteMessage(this, message.id);
        pushMessageToConversation(this, { ...data, status: 'sent' });
      } catch (error) {
        pushMessageToConversation(this, { ...message, status: 'failed' });
        updateMessageMeta(this, {
          id,
          meta: { ...meta, error: '' },
        });
      }
    },
    async sendAttachment(params) {
      const appConfig = useAppConfigStore();
      const contacts = useContactsStore();
      const {
        attachment: { thumbUrl, fileType },
        meta = {},
      } = params;
      const attachment = {
        thumb_url: thumbUrl,
        data_url: thumbUrl,
        file_type: fileType,
        status: 'in_progress',
      };
      const tempMessage = createTemporaryMessage({
        attachments: [attachment],
      });
      pushMessageToConversation(this, tempMessage);
      try {
        const { conversation, contact, account_id } = contacts.getCurrentUser;
        const websiteToken = appConfig.getWebsiteToken;
        const { data } = await sendAttachmentAPI({
          ...params,
          websiteToken,
          conversationId: conversation.id,
          accountId: account_id,
          contactId: contact.id,
        });
        updateAttachmentMessageStatus(this, {
          message: data,
          tempId: tempMessage.id,
        });
        pushMessageToConversation(this, { ...data, status: 'sent' });
      } catch (error) {
        pushMessageToConversation(this, { ...tempMessage, status: 'failed' });
        updateMessageMeta(this, {
          id: tempMessage.id,
          meta: { ...meta, error: error.response?.data?.message || '' },
        });
        // Show error
      }
    },
    async fetchMissingMessages() {
      const earliestMessage = this.getLastMessage;
      const earliestMessageId = earliestMessage.id;
      const messages = await this.fetchOldConversations({
        after: earliestMessageId,
      });
      if (messages.length === 20) {
        await this.fetchMissingMessages();
      }
    },
    async fetchOldConversations({ before, after } = {}) {
      const appConfig = useAppConfigStore();
      const contacts = useContactsStore();
      try {
        setConversationListLoading(this, true);
        const { conversation } = contacts.getCurrentUser;
        const websiteToken = appConfig.getWebsiteToken;
        const { data } = await getMessagesAPI({
          before,
          after,
          conversationId: conversation.id,
          websiteToken,
        });
        const formattedMessages = getNonDeletedMessages({ messages: data });
        setMessagesInConversation(this, formattedMessages);
        setConversationListLoading(this, false);
        return formattedMessages;
      } catch (error) {
        setConversationListLoading(this, false);
      }
    },

    clearConversations() {
      this.conversations = {};
    },

    async addOrUpdateMessage(data) {
      const { id, content_attributes } = data;
      if (content_attributes && content_attributes.deleted) {
        deleteMessage(this, id);
        return;
      }
      pushMessageToConversation(this, data);
    },

    toggleAgentTyping(data) {
      const isTyping = data.status === 'on';
      this.uiFlags.isAgentTyping = isTyping;
    },

    async createConversation(params, recursionLimiter = false) {
      const appConfig = useAppConfigStore();
      const contacts = useContactsStore();
      setConversationUIFlag(this, { isCreating: true });
      const websiteToken = appConfig.getWebsiteToken;
      try {
        const currentUser = contacts.getCurrentUser;
        if (currentUser && currentUser.conversation) {
          const { conversation } = currentUser;
          if (conversation && conversation.id) {
            const conversationId = conversation.id;
            const conversationFound = await getConversationAPI({
              conversationId,
              websiteToken,
            });
            await updateConversationAPI(conversationId, websiteToken, params);
            await this.setWebsocketConnection(currentUser);
            setMessagesInConversation(this, conversationFound.data.messages);
            await retryWithDelay();
            appConfig.toggleUiFlags({ showMessage: true }, { root: true });
          }
        } else {
          const { message, ...payloadData } = params;
          const response = await createConversationAPI({
            ...payloadData,
            websiteToken,
          });
          const { payload } = response.data;
          this.setWebsocketConnection(payload);
          await retryWithDelay();
          setHeader('auth-widget', payload.contactInbox.pubsubToken);
          const { data } = await getMessagesAPI({
            conversationId: payload.conversation.id,
            websiteToken,
          })
          setMessagesInConversation(this, data);
          appConfig.toggleUiFlags({ showMessage: true }, { root: true });
          contacts.saveUser(payload, { root: true });
          if (message) {
            this.sendMessage({ content: message.content });
          }
          appConfig.toggleUiFlags({ showPreChatForm: false }, { root: true });
        }
      } catch (error) {
        if (!recursionLimiter) {
          this.createConversation(params, true);
        }
        contacts.clearUser(websiteToken, { root: true });
      } finally {
        setConversationUIFlag(this, { isCreating: false });
      }
    },

    setWebsocketConnection(payload) {
      const app = window.novaTalks.WIDGET;
      const { contactInbox, account_id } = payload;
      window.novaTalks.actionCable = new BaseActionCableConnector(app, contactInbox.pubsubToken, account_id);
    },

    async updateMessageDelivered({ id }) {
      const contacts = useContactsStore();
      const { conversation } = contacts.getCurrentUser;
      this.conversations[id].status = MESSAGE_STATUS.DELIVERED;
      try {
        await updateMessageStatusAPI({
          conversationId: conversation.id,
          messageIds: [id],
          status: MESSAGE_STATUS.DELIVERED,
        });
      } catch (e) {
        console.log(e);
      }
    },

    pushUpdateMessagesStatus({ messages = [] }) {
      messages.forEach((id) => {
        this.conversations[id].status = MESSAGE_STATUS.SEEN;
      });
      const queue = [...this.updateMessagesQueue];
      messages.forEach((id) => {
        if (!queue.includes(id)) {
          queue.push(id);
        }
      });
      this.updateMessagesQueue = queue;
    },

    async updateMessagesStatus() {
      if (window.novaTalks.actionCable?.consumer?.readyState !== WebSocket.OPEN) {
        return true;
      }
      if (this.updateMessagesQueue.length === 0) {
        return false;
      }
      const contacts = useContactsStore();
      const { conversation } = contacts.getCurrentUser;
      try {
        await updateMessageStatusAPI({
          conversationId: conversation.id,
          messageIds: this.updateMessagesQueue,
          status: MESSAGE_STATUS.SEEN,
        });
        this.updateMessagesQueue.forEach((id) => {
          this.conversations[id].status = MESSAGE_STATUS.SEEN;
        });
        this.updateMessagesQueue = [];
      } catch (e) {
        console.log(e);
        if (e.code?.includes('ERR_NETWORK')) {
          return true;
        }
      }
    },
  },
});
