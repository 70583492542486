<template>
  <div class="widget-footer-wrap" v-if="!showPreChatForm">
    <div class="widget-footer tw-novatalks-bg-slate-50">
      <chat-input-wrap
        v-if="getUiFlags.showMessage"
        :on-send-message="handleSendMessage"
        :on-send-attachment="handleSendAttachment"
      />
      <custom-button
        v-if="!getUiFlags.showMessage && showButton"
        class="tw-novatalks-font-medium"
        block
        :bg-color="widgetColor"
        :text-color="textColor"
        :disabled="isCreating"
        @click.prevent="onSubmit"
      >
        <spinner v-if="isCreating" class="tw-novatalks-p-0" />
        {{ isNewConversation ? $t('START_CONVERSATION') : $t('CONTINUE_CONVERSATION') }}
      </custom-button>
      <branding />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import ChatInputWrap from './ChatInputWrap.vue';
import CustomButton from '../common/Button.vue';
import Branding from '../common/Branding.vue';
import Spinner from '../common/Spinner.vue';
import { getContrastingTextColor } from '@/helpers/utils';
import { getLangAttribute, getBrowserLink } from '@/helpers/conversation';
import { useAppConfigStore } from '@/stores/appConfig';
import { useContactsStore } from '@/stores/contacts';
import { useConversationStore } from '@/stores/conversation';

export default {
  name: 'ChatFooter',
  components: {
    ChatInputWrap,
    CustomButton,
    Spinner,
    Branding,
  },
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(useAppConfigStore, {
      getUiFlags: (state) => state.getUiFlags,
      widgetColor: (state) => state.getWidgetColor,
      isEnabledLangAttribute: (state) => state.getIsEnabledLangAttribute,
      isEnabledLinkAttribute: (state) => state.getIsEnabledLinkAttribute,
      getPreChatFormOptions: (state) => state.getPreChatFormOptions,
      isEnabledOSAttribute: (state) => state.getIsEnabledOSAttribute,
      isEnabledBrowserAttribute: (state) => state.getIsEnabledBrowserAttribute,
      isEnabledIpCountryAttribute: (state) => state.getIsEnabledIpCountryAttribute,
    }),
    ...mapState(useContactsStore, {
      isNewConversation: (state) => state.isNewConversation,
    }),
    ...mapState(useContactsStore, {
      isCreating: (state) => state.getIsCreating,
    }),
    textColor() {
      return getContrastingTextColor(this.widgetColor);
    },
    showPreChatForm() {
      return (
        this.getUiFlags.showPreChatForm &&
        !this.getUiFlags.showMessage &&
        this.isNewConversation &&
        this.getPreChatFormOptions.enabled
      );
    },
    osVersion(){
      const OSList = {
        "Mac": "Mac",
        "Win": "Windows",
        "Linux": "Linux",
      };
      const hash = navigator.platform;
      return OSList[Object.keys(OSList).find(el => hash.includes(el))]
    },
    browserVersion() {
      const browserList = {
        "Edg": "Microsoft Edge",
        "MSIE": "Internet Explorer",
        "Brave": "Brave",
        "Vivaldi": "Vivaldi",
        "Chrome": "Google Chrome",
        "Safari": "Safari",
        "OPR": "Opera",
        "Firefox": "Mozilla Firefox",
      }
      const hash = navigator.userAgent;
      return browserList[Object.keys(browserList).find(el => hash.includes(el))]
    }
  },

  methods: {
    ...mapActions(useConversationStore, ['sendMessage', 'sendAttachment', 'createConversation', 'updateTextAreaHeight']),
    async handleSendMessage(content) {
      await this.sendMessage({
        content,
      });
      this.updateTextAreaHeight(32);
    },
    handleSendAttachment(attachment) {
      this.sendAttachment({ attachment });
    },
    onSubmit() {
      const payload = {};
      payload.custom_attributes = {};
      payload.custom_attributes.isEnabledIpCountryAttribute = this.isEnabledIpCountryAttribute;
      if (this.isEnabledBrowserAttribute) {
        payload.custom_attributes.browser = this.browserVersion;
      }
      if (this.isEnabledOSAttribute) {
        payload.custom_attributes.OS = this.osVersion;
      }
      if (this.isEnabledLangAttribute) {
        payload.custom_attributes.language = getLangAttribute();
      }
      if (this.isEnabledLinkAttribute) {
        payload.custom_attributes.browser_link = getBrowserLink();
      }
      this.createConversation(payload);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins.scss';
#nova-talks-app {
  .widget-footer-wrap {
    display: flex;
    justify-content: center;
  }

  .widget-footer {
    box-sizing: border-box;
    width: 90%;
    border-radius: 16px;
  }
}
</style>
