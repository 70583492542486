<template>
  <label :class="{ block: !textarea, 'tw-novatalks-block': textarea }">
    <div
      v-if="label"
      class="tw-novatalks-mb-2 tw-novatalks-text-xs tw-novatalks-font-medium"
      :class="{
        'tw-novatalks-text-black-800': !error,
        'tw-novatalks-text-red-400': error,
      }"
    >
      {{ label }}
    </div>
    <textarea
      v-if="textarea"
      class="tw-novatalks-resize-none tw-novatalks-border tw-novatalks-rounded tw-novatalks-w-full tw-novatalks-py-2 tw-novatalks-px-3 tw-novatalks-text-slate-700 tw-novatalks-leading-tight tw-novatalks-outline-none"
      :class="{
        'tw-novatalks-border-black-200 hover:tw-novatalks-border-black-300 focus:tw-novatalks-border-black-300': !error,
        'tw-novatalks-border-red-200 hover:tw-novatalks-border-red-300 focus:tw-novatalks-border-red-300': error,
      }"
      :placeholder="placeholder"
      :value="modelValue"
      @input="onChange"
    />
    <select
      v-else-if="isList"
      :type="type"
      class="tw-novatalks-border tw-novatalks-rounded tw-novatalks-w-full tw-novatalks-py-2 tw-novatalks-px-3 tw-novatalks-text-slate-700 tw-novatalks-leading-tight tw-novatalks-outline-none"
      :class="{
        'tw-novatalks-border-black-200 hover:tw-novatalks-border-black-300 focus:tw-novatalks-border-black-300': !error,
        'tw-novatalks-border-red-200 hover:tw-novatalks-border-red-300 focus:tw-novatalks-border-red-300': error,
      }"
      :value="modelValue"
      @input="onChange"
    >
      <option value="" selected disabled hidden>
        {{ placeholder }}
      </option>
      <option v-for="(item, index) in listValues" :key="index" :value="`'${item}'`">{{ item }}</option>
    </select>
    <input
      v-else
      :type="type"
      class="tw-novatalks-border tw-novatalks-rounded tw-novatalks-w-full tw-novatalks-py-2 tw-novatalks-px-3 tw-novatalks-text-slate-700 tw-novatalks-leading-tight tw-novatalks-outline-none"
      :class="{
        'tw-novatalks-border-black-200 hover:tw-novatalks-border-black-300 focus:tw-novatalks-border-black-300': !error,
        'tw-novatalks-border-red-200 hover:tw-novatalks-border-red-300 focus:tw-novatalks-border-red-300': error,
      }"
      :placeholder="placeholder"
      :value="modelValue"
      @input="onChange"
    />
    <div v-if="error" class="tw-novatalks-text-red-400 tw-novatalks-text-xs tw-novatalks-font-medium">
      {{ error }}
    </div>
  </label>
</template>
<script>
export default {
  name: 'ChatAttachment',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    listValues: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isList() {
      return this.type === 'list';
    },
  },
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped>
.tag {
  position: absolute;
}
</style>
