import { defineStore } from 'pinia';
import { useContactsStore } from './contacts';
import { useConversationStore } from './conversation';

export const useMessageStore = defineStore('message', {
  state: () => ({
    uiFlags: {
      isUpdating: false,
    },
  }),
  getters: {
    getUIFlags: (state) => state.uiFlags,
  },
  actions: {
    async update({ email, messageId, submittedValues }) {
      const contacts = useContactsStore();
      const conversation = useConversationStore();
      this.uiFlags.isUpdating = true;
      try {
        // lol, where there was MessageAPI anyways? No PATCH endpoints on API either
        // await MessageAPI.update({
        //   email,
        //   messageId,
        //   values: submittedValues
        // });
        conversation.updateMessage(
          {
            id: messageId,
            content_attributes: {
              submitted_email: email,
              submitted_values: email ? null : submittedValues,
            },
          },
          { root: true }
        );
        contacts.getCurrentUser({}, { root: true });
      } catch (error) {
        // Ignore error
      }
      this.uiFlags.isUpdating = false;
    },
  },
});
