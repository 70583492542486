<template>
  <div class="widget-chat-wrap tw-novatalks-bg-slate-50" :class="widgetChatWrapClass" :style="widgetWrapStyle">
    <chat-header :title="getWidgetHeading || $t('WIDGET_TITLE')" />
    <div
      v-if="showPreChatForm"
      :class="getConversationContainerClass"
      ref="conversationContainer">
      <pre-chat-form/>
    </div>
    <div :class="getConversationContainerClass" ref="conversationContainer"
         :style="`height: calc(100% - ${62 + textAreaHeight + 56}px) !important`" >
      <div v-if="getUiFlags.showMessage">
        <div class="widget-conversation-wrap">
          <div v-if="isFetchingList" class="message--loader">
            <spinner />
          </div>
          <div v-for="groupedMessage in groupedMessages" :key="groupedMessage.date" class="messages-wrap">
            <date-separator :date="groupedMessage.date"></date-separator>
            <chat-message v-for="message in groupedMessage.messages" :key="message.id" :message="message" />
          </div>
        </div>
      </div>
    </div>
    <chat-footer :show-button="!showPreChatForm" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppConfigStore } from '@/stores/appConfig';
import { useContactsStore } from '@/stores/contacts';
import { useConversationStore } from '@/stores/conversation';
import ChatHeader from './chat/ChatHeader.vue';
import ChatFooter from './chat/ChatFooter.vue';
import PreChatForm from './PreChatForm.vue';

import ChatMessage from './chat/ChatMessage.vue';
import DateSeparator from './chat/DateSeparator.vue';
import Spinner from './common/Spinner.vue';
import { MESSAGE_STATUS, MESSAGE_TYPE } from '@/constants/messages';

export default {
  name: 'ChatView',
  components: {
    ChatHeader,
    ChatFooter,
    PreChatForm,
    ChatMessage,
    DateSeparator,
    Spinner,
  },
  mixins: [],
  data() {
    return {
      previousScrollHeight: 0,
      previousConversationSize: 0,
    };
  },
  computed: {
    ...mapState(useAppConfigStore, {
      getUiFlags: (state) => state.getUiFlags,
      getWidgetHeading: (state) => state.getWidgetHeading,
      branding: (state) => state.getBranding,
      getPosition: (state) => state.getWidgetPosition,
      getMargin: (state) => state.getWidgetMargin,
      isInIframe: (state) => state.getIsInIframe,
      getPreChatFormOptions: (state) => state.getPreChatFormOptions,
    }),
    ...mapState(useContactsStore, {
      isNewConversation: (state) => state.isNewConversation,
    }),
    ...mapState(useConversationStore, {
      earliestMessage: (state) => state.getEarliestMessage,
      allMessagesLoaded: (state) => state.getAllMessagesLoaded,
      isFetchingList: (state) => state.getIsFetchingList,
      conversationSize: (state) => state.getConversationSize,
      getLastMessage: (state) => state.getLastMessage,
      groupedMessages: (state) => state.getGroupedConversation,
      textAreaHeight: (state) => state.getTextAreaHeight,
    }),
    getContainer() {
      return this.$refs.conversationContainer;
    },
    isDisableBranding() {
      return this.branding.poweredByIsDisabled;
    },
    getConversationContainerClass() {
      const { showMessage } = this.getUiFlags;
      let cls = 'tw-novatalks-overflow-auto widget-conversation-container';
      if (!showMessage && !this.showPreChatForm) {
        cls += ' tw-novatalks-flex';
      }
      if (this.isDisableBranding) {
        cls += ' widget-conversation-container-disabled-branding';
      }
      if (this.showPreChatForm) {
        cls += ' widget-enabled-pre-chat-form';
      }
      return cls;
    },
    showPreChatForm() {
      return (
        this.getUiFlags.showPreChatForm &&
        !this.getUiFlags.showMessage &&
        this.isNewConversation &&
        this.getPreChatFormOptions.enabled
      );
    },
    widgetChatWrapClass() {
      return this.getPosition;
    },
    isTabFocused() {
      return this.getUiFlags.isTabFocused;
    },

    widgetWrapStyle() {
      const styles = {};
      if (this.getPosition === 'bottom-left' || this.getPosition === 'bottom-right') {
        styles.bottom = '95px !important';
      }
      if (this.getPosition === 'middle-left' || this.getPosition === 'middle-right') {
        styles.bottom = '20px !important';
      }
      if (this.getPosition === 'bottom-left' || this.getPosition === 'middle-left') {
        styles.left = `${this.getMargin} !important`;
      }
      if (this.getPosition === 'bottom-right' || this.getPosition === 'middle-right') {
        styles.right = `${this.getMargin} !important`;
      }
      return styles;
    },
  },
  watch: {
    allMessagesLoaded() {
      this.previousScrollHeight = 0;
    },
    isTabFocused(value) {
      if (value) {
        this.seeMessages();
      }
    },
    textAreaHeight(){
      setTimeout(()=> {
        this.getContainer.scrollTop = this.getContainer.scrollHeight}, 10)
    }
  },
  mounted() {
    this.getContainer.addEventListener('scroll', this.handleScroll);
    this.scrollToBottom();
  },
  updated() {
    if (this.previousConversationSize !== this.conversationSize) {
      this.previousConversationSize = this.conversationSize;
      if (!this.isFetchingList) {
        this.scrollToBottom();
      }
    }
  },
  unmounted() {
    this.getContainer.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(useConversationStore, ['fetchOldConversations', 'pushUpdateMessagesStatus']),
    scrollToBottom() {
      const container = this.getContainer;
      container.scrollTop = container.scrollHeight - this.previousScrollHeight;
      this.previousScrollHeight = 0;
    },
    handleScroll() {
      const container = this.getContainer;
      if (this.isFetchingList || this.allMessagesLoaded || !this.conversationSize) {
        return;
      }
      if (container.scrollTop < 100) {
        this.fetchOldConversations({ before: this.earliestMessage.id });
        this.previousScrollHeight = container.scrollHeight;
      }
    },
    seeMessages() {
      const messages = [];
      this.groupedMessages.forEach((groupedMessage) => {
        groupedMessage.messages.forEach((message) => {
          if (message.status !== MESSAGE_STATUS.SEEN && message.message_type === MESSAGE_TYPE.OUTGOING) {
            messages.push(message.id);
          }
        });
      });
      if (messages.length > 0) {
        this.pushUpdateMessagesStatus({ messages });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins.scss';

.widget-chat-wrap {
  display: block !important;
  height: 500px !important;
  width: 400px !important;
  position: fixed !important;
  right: 20px !important;
  border-radius: 16px !important;
  box-shadow: 0 5px 15px rgb(0 0 0 / 16%) !important;
  bottom: 95px !important;
  z-index: 1000000000 !important;
}
.widget-conversation-container {
  border-radius: 16px !important;
}

.widget-conversation-container-disabled-branding {
  height: calc(100% - 63px - 66px) !important;
}
.widget-enabled-pre-chat-form {
  height: calc(100% - 63px) !important;
  text-align: left !important;
}

@media (max-width: 425px) {
  .widget-chat-wrap {
    height: 400px !important;
    width: 350px !important;
  }
}

#nova-talks-app {
  .widget-conversation-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $space-large $space-small $space-small $space-small;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  .message--loader {
    text-align: center;
  }
}
</style>
