<template>
  <div role="dialog" class="widget-emoji-dialog">
    <header class="widget-emoji-dialog--header" role="menu">
      <ul>
        <li
          v-for="category in Object.keys(emojis)"
          :key="category"
          :class="{ active: selectedKey === category }"
          @click="changeCategory(category)"
        >
          <button v-dompurify-html="emojis[category][0]" class="widget-emoji--item" @click="changeCategory(category)" />
        </li>
      </ul>
    </header>
    <div class="widget-emoji--row">
      <h5 class="widget-emoji-category--title">
        {{ selectedKey }}
      </h5>
      <button
        v-for="emoji in emojis[selectedKey]"
        :key="emoji"
        v-dompurify-html="emoji"
        class="widget-emoji--item"
        track-by="$index"
        @click="onClick(emoji)"
      />
    </div>
  </div>
</template>

<script>
import emojis from './emojis.json';

export default {
  name: 'EmpjiInput',
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedKey: 'Smileys & Emotion',
      emojis,
    };
  },
  methods: {
    changeCategory(category) {
      this.selectedKey = category;
    },
  },
};
</script>
<style lang="scss" scoped>
/**
 * All the units used below are pixels due to variable name conflict in widget and dashboard
 **/
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

$space-smaller: 4px;
$space-small: 8px;
$space-one: 10px;
$space-slab: 12px;
$space-normal: 16px;
$space-two: 20px;
$space-medium: 24px;

$font-size-small: 14px;
$font-size-default: 16px;
$font-size-medium: 18px;
#nova-talks-app {
  .widget-emoji-dialog {
    @include elegant-card;
    background: $color-white;
    border-radius: $space-small;
    box-sizing: content-box;
    position: absolute;
    right: 0;
    bottom: 90px;
    width: 32 * $space-one;

    &::before {
      @include arrow(bottom, $color-white, $space-slab);
      bottom: -$space-slab;
      position: absolute;
      right: $space-two;
    }

    .widget-emoji--item {
      cursor: pointer;
      background: transparent;
      border: 0;
      font-size: $font-size-medium;
      margin: 0;
      padding: 0;
      width: 22px;
      height: 22px;
    }

    .widget-emoji--row {
      box-sizing: border-box;
      height: $space-one * 18;
      overflow-y: auto;
      padding: $space-smaller $space-normal;

      .widget-emoji--item {
        float: left;
        margin: $space-smaller;
        line-height: 1.5;
      }
    }

    .widget-emoji-category--title {
      color: $color-heading;
      font-size: $font-size-small;
      font-weight: 500;
      line-height: 1.5;
      margin: 0;
      text-transform: capitalize;
    }
  }

  .widget-emoji-dialog--header {
    background-color: $color-body;
    border-top-left-radius: $space-small;
    border-top-right-radius: $space-small;
    padding: $space-smaller $space-smaller 0;

    ul {
      display: flex;
      list-style: none;
      overflow: auto;
      overflow-y: hidden;
      margin: 0;
      padding: 0;
      border-top-left-radius: $space-smaller;
      border-top-right-radius: $space-smaller;

      > li {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 2.4 * $space-one;
        justify-content: center;
        padding: $space-smaller $space-small;
      }

      > .active {
        background: $color-white;
        border-top-left-radius: $space-smaller;
        border-top-right-radius: $space-smaller;
      }
    }
  }
}
</style>
