export const findUndeliveredMessage = (messageInbox, { content }) =>
  Object.values(messageInbox).filter((message) => message.content === content && message.status === 'in_progress');

export const getLangAttribute = () => {
  let lang = 'en';
  const locales = ['en', 'uk', 'ru', 'az', 'es'];
  const docLang = window.novaTalks.documentLang;
  if (typeof docLang === 'string' && docLang.length > 1) {
    lang = docLang;
    let allowed = false;
    locales.forEach((locale) => {
      if (lang.toLowerCase().includes(locale)) {
        allowed = true;
        lang = locale;
      }
    });
    if (!allowed) {
      lang = 'en';
    }
  }
  return lang;
};

export const getBrowserLink = () => {
  return window.novaTalks.browserLink;
};
