import { initApplication } from './main.js';

if (window.self !== window.top) {
  const initializeWidget = () => {
    const htmlBody = document.body;
    const widgetHolder = document.createElement('div');
    widgetHolder.setAttribute('id', 'nova-talks');
    htmlBody.appendChild(widgetHolder);
  };
  const handlePostMessage = (event) => {
    if (event.data?.event === 'ntk-init-widget' && event.data?.novaTalks) {
      window.novaTalks = { ...window.novaTalks, ...event.data.novaTalks };
      if (window.novaTalks.axios && window.novaTalks.baseUrl) {
        window.novaTalks.axios.defaults.baseURL = window.novaTalks.baseUrl;
      }
      if (event.data?.userData) {
        window.novaTalks.contact = event.data?.userData;
      }
      initializeWidget();
      initApplication();
    }
    if (event.data?.event === 'ntk-widget-blur') {
      window.dispatchEvent(new Event('ntk-widget-blur'));
    }
    if (event.data?.event === 'ntk-widget-focus') {
      window.dispatchEvent(new Event('ntk-widget-focus'));
    }
    if (event.data?.event === 'ntk-widget-visibilitychange') {
      window.dispatchEvent(new Event('ntk-widget-visibilitychange'));
    }
  };
  window.addEventListener('message', handlePostMessage, false);
} else {
  const isDev =
    process?.env?.NODE_ENV === 'development'
      ? !(window.novaTalks.isRenderedInIframe && window.self === window.top)
      : true;
  if (isDev) {
    if (window.novaTalks.axios && window.novaTalks.baseUrl) {
      window.novaTalks.axios.defaults.baseURL = window.novaTalks.baseUrl;
    }
    initApplication();
  }
}
