<template>
  <div class="user-message-wrap">
    <div class="user-message">
      <div class="message-wrap" :class="{ 'in-progress': isInProgress, 'is-failed': isFailed }">
        <user-message-bubble
          v-if="showTextBubble"
          :message="message.content"
          :status="message.status"
          :widget-color="widgetColor"
        />
        <div v-if="hasAttachments" class="chat-bubble has-attachment user" :style="{ backgroundColor: widgetColor }">
          <div v-for="attachment in message.attachments" :key="attachment.id">
            <image-bubble
              v-if="attachment.file_type === 'image' && !hasImageError"
              :url="attachment.data_url"
              :thumb="attachment.thumb_url"
              :readable-time="readableTime"
              @error="onImageLoadError"
            />
            <file-bubble v-else :url="attachment.data_url" :is-in-progress="isInProgress" />
            <div v-if="isFailed" style="display: flex; font-size: 1rem">
              <fluent-icon style="margin-right: 10px" icon="dismiss-circle" size="24" />
              <p>{{ errorMessage }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="isFailed"
          class="tw-novatalks-flex tw-novatalks-justify-end tw-novatalks-align-middle tw-novatalks-px-4 tw-novatalks-py-2 tw-novatalks-text-red-700"
        >
          <button
            v-if="!hasAttachments"
            :title="$t('COMPONENTS.MESSAGE_BUBBLE.RETRY')"
            class="tw-novatalks-inline-flex tw-novatalks-justify-center tw-novatalks-items-center tw-novatalks-ml-2"
            @click="retrySendMessage"
          >
            <fluent-icon icon="arrow-clockwise" size="14" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import UserMessageBubble from '@/components/messages/UserMessageBubble.vue';
import ImageBubble from '@/components/common/ImageBubble.vue';
import FluentIcon from '@/components/common/FluentIcon/Index';
import FileBubble from '@/components/common/FileBubble';
import timeMixin from '@/mixins/time';
import messageMixin from '@/mixins/messageMixin';
import { useAppConfigStore } from '@/stores/appConfig';
import { useConversationStore } from '@/stores/conversation';

export default {
  name: 'UserMessage',
  components: {
    UserMessageBubble,
    ImageBubble,
    FileBubble,
    FluentIcon,
  },
  mixins: [timeMixin, messageMixin],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasImageError: false,
    };
  },
  computed: {
    ...mapState(useAppConfigStore, {
      widgetColor: (state) => state.getWidgetColor,
    }),
    isInProgress() {
      const { status = '' } = this.message;
      return status === 'in_progress';
    },
    showTextBubble() {
      const { message } = this;
      return !!message.content;
    },
    readableTime() {
      const { created_at: createdAt = '' } = this.message;
      return this.messageStamp(createdAt);
    },
    isFailed() {
      const { status = '' } = this.message;
      return status === 'failed';
    },
    errorMessage() {
      const { meta } = this.message;
      return meta ? meta.error : this.$t('COMPONENTS.MESSAGE_BUBBLE.ERROR_MESSAGE');
    },
  },
  watch: {
    message() {
      this.hasImageError = false;
    },
  },
  mounted() {
    this.hasImageError = false;
  },
  methods: {
    ...mapActions(useConversationStore, ['sendMessageWithData']),
    async retrySendMessage() {
      await this.sendMessageWithData(this.message);
    },
    onImageLoadError() {
      this.hasImageError = true;
    },
  },
};
</script>
