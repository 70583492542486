<template>
  <button :class="buttonClassName" :style="buttonStyles" :disabled="disabled" @click="onClick">
    <slot />
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'blue',
    },
    bgColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    buttonClassName() {
      let className = ['text-white', 'py-3', 'px-4', 'rounded', 'shadow-sm'].map((x) => 'tw-novatalks-' + x).join(' ');

      if (this.type === 'clear') {
        className = ['flex', 'mx-auto', 'mt-4', 'text-xs', 'w-auto', 'text-black-600']
          .map((x) => 'tw-novatalks-' + x)
          .join(' ');
      }

      if (this.type === 'blue' && !Object.keys(this.buttonStyles).length) {
        className = `${className} tw-novatalks-bg-woot-500 hover:tw-novatalks-bg-woot-700`;
      }
      if (this.block) {
        className = `${className} tw-novatalks-w-full`;
      }
      if (this.disabled) {
        className = `${className} woot-button-disabled`;
      }
      return className;
    },
    buttonStyles() {
      const styles = {};
      if (this.bgColor) {
        styles.backgroundColor = this.bgColor;
      }
      if (this.textColor) {
        styles.color = this.textColor;
      }
      return styles;
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
#nova-talks-app {
  .woot-button-disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
