<template>
  <div class="chat-bubble-wrap">
    <button class="chat-bubble agent" :class="$dm('tw-novatalks-bg-white', 'dark:bg-slate-50')" @click="onClickMessage">
      <div v-if="showSender" class="row--agent-block">
        <span class="agent--name">{{ agentName }}</span>
      </div>
      <div v-dompurify-html="`${formatMessage(message, false).slice(0, 30)}...`" class="message-content" />
    </button>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import messageFormatterMixin from '@/mixins/messageFormatterMixin';

import darkModeMixin from '@/mixins/darkModeMixin';
import { useAppConfigStore } from '@/stores/appConfig';

export default {
  name: 'UnreadMessage',
  mixins: [messageFormatterMixin, darkModeMixin],
  props: {
    message: {
      type: String,
      default: '',
    },
    showSender: {
      type: Boolean,
      default: false,
    },
    sender: {
      type: Object,
      default: () => {},
    },
    campaignId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    avatarUrl() {
      const displayImage = '/images/bot.png';
      if (this.isSenderExist(this.sender)) {
        const { avatar_url: avatarUrl } = this.sender;
        return avatarUrl;
      }
      return displayImage;
    },
    agentName() {
      if (this.isSenderExist(this.sender)) {
        const { available_name: availableName, name } = this.sender;
        return availableName || name;
      }
      return this.$t('UNREAD_VIEW.BOT');
    },
  },
  methods: {
    ...mapActions(useAppConfigStore, ['toggleUiFlags']),
    isSenderExist(sender) {
      return sender;
    },
    onClickMessage() {
      this.toggleUiFlags({
        showUnreadMessage: false,
        isShowChat: true,
        isShowChannels: false,
        isBobbleOpen: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .chat-bubble {
    max-width: 85%;
    padding: $space-normal;
    cursor: pointer;
  }

  .row--agent-block {
    align-items: center;
    display: flex;
    text-align: left;
    padding-bottom: $space-small;
    font-size: $font-size-small;
    .agent--name {
      font-weight: $font-weight-medium;
      margin-left: $space-smaller;
    }
    .company--name {
      color: $color-light-gray;
      margin-left: $space-smaller;
    }
  }
}
</style>
