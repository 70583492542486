import axios from 'axios';
import axiosRetry from 'axios-retry';

export const APP_BASE_URL = 'https://api.novatalks.ai';

const createAPI = () => {
  const app = axios.create({
    baseURL: APP_BASE_URL,
    withCredentials: false,
  });

  axiosRetry(app, {
    retries: 3,
    retryCondition: (error) => {
      console.error(error);
      let status = error.response?.status;
      const omitStatuses = [400, 401, 413];
      if (omitStatuses.includes(status)) {
        return false;
      }
      return true;
    },
  });

  window.novaTalks = {
    ...window.novaTalks,
    axios: app,
  };

  return app;
};

export const API = createAPI();

export const setHeader = (key, value) => {
  API.defaults.headers.common[key] = value;
};

export const removeHeader = (key) => {
  delete API.defaults.headers.common[key];
};
