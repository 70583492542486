<template>
  <textarea
    class="text-area"
    ref="textarea"
    :placeholder="placeholder"
    :value="modelValue"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useConversationStore } from '@/stores/conversation';

export default {
  name: 'ResizableTextArea',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
      default: 2,
    },
  },
  emits: ['blur', 'focus', 'update:modelValue'],
  data() {
    return {
      inputtedHeight:'',
    };
  },
  computed:{
    ...mapState(useConversationStore, {
      textAreaHeight: (state) => state.getTextAreaHeight,
    }),
  },
  watch:{
    textAreaHeight(val){
      if(val === 32){
        this.resizeTextarea();
      }
    }
  },
  methods: {
    ...mapActions(useConversationStore, ['updateTextAreaHeight']),
    resizeTextarea() {
      this.$el.style.height = '0px';
      const heightParam = this.$el.scrollHeight < 104 ? this.$el.scrollHeight : 96
      this.$el.style.height = `${heightParam}px`;
      this.updateTextAreaHeight(heightParam);
    },
    onInput(event) {
      this.resizeTextarea();
      this.$emit('update:modelValue', event.target.value);
    },
    onBlur() {
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
    focus() {
      this.$refs.textarea.focus();
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep.text-area{
  max-height: 104px !important;
  min-height: 32px !important;
}
</style>