<template>
  <a :href="url" target="_blank" rel="noreferrer noopener nofollow" class="widget-image">
    <div class="widget-wrap-image">
      <img :src="thumb" alt="Picture message" @click="onClick" @error="onImgError" class="widget-image-bubble-size" />
      <span class="widget--wrap-image-time">{{ readableTime }}</span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ImageBubble',
  props: {
    url: { type: String, default: '' },
    thumb: { type: String, default: '' },
    readableTime: { type: String, default: '' },
  },
  emits: ['click', 'error'],
  methods: {
    onImgError() {
      this.$emit('error');
    },
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .widget-image {
    display: block;

    .widget-wrap-image {
      position: relative;
      display: flex;
      max-width: 100%;

      &::before {
        background-image: linear-gradient(-180deg, transparent 3%, $color-heading 130%);
        bottom: 0;
        content: '';
        height: 20%;
        left: 0;
        opacity: 0.8;
        position: absolute;
        width: 100%;
      }
    }

    .widget-image-bubble-size {
      width: 100%;
      max-width: 250px;
    }

    .widget--wrap-image-time {
      font-size: $font-size-small;
      bottom: $space-smaller;
      color: $color-white;
      position: absolute;
      right: $space-slab;
      white-space: nowrap;
    }
  }
}
</style>
