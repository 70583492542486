import { createConversation, sendMessage, sendAttachment } from './endPoints';
import { API } from '../helpers/axios';

const createConversationAPI = async (content) => {
  const urlData = createConversation(content);
  return API.post(urlData.url, urlData.params);
};

const updateConversationAPI = (conversationId, websiteToken, data) => {
  return API.patch(`/widget/conversations/${conversationId}?website_token=${websiteToken}`,data)
};

const sendMessageAPI = async (content) => {
  const urlData = sendMessage(content);
  const result = await API.post(urlData.url, urlData.params);
  return result;
};

const sendAttachmentAPI = async (attachment) => {
  const urlData = sendAttachment(attachment);
  const result = await API.post(urlData.url, urlData.params);
  return result;
};

const getConversationAPI = async (params) => {
  return API.get(`/widget/conversations/${params.conversationId}?website_token=${params.websiteToken}`);
};

const getMessagesAPI = async ({ before, websiteToken, conversationId, after }) => {
  const params = {
    website_token: websiteToken,
    before: typeof before === 'number' ? before : undefined,
    after: typeof after === 'number' ? after : undefined,
  };

  return API.get(`/widget/conversations/${conversationId}/messages`, {
    params,
  });
};

const updateMessageStatusAPI = async ({ conversationId, messageIds, status }) => {
  return API.patch(`/widget/conversations/${conversationId}/messages/status`, {
    status,
    messageIds,
  });
};

export {
  createConversationAPI,
  updateConversationAPI,
  sendMessageAPI,
  getConversationAPI,
  sendAttachmentAPI,
  getMessagesAPI,
  updateMessageStatusAPI,
};
