const generateEchoId = () => `${new Date().getMilliseconds()}`;

export const createConversation = (params) => {
  const payload = {
    url: `/widget/conversations?website_token=${params.websiteToken}`,
    params: {
      name: params.name || `widget-contact-${generateEchoId()}`,
      email: params.email || undefined,
      phone_number: params.phone_number || undefined,
      custom_attributes: params.custom_attributes,
    },
  };
  if (params.message) {
    payload.params.message = {
      content: params.message.content,
      echo_id: generateEchoId(),
    };
  }

  return payload;
};

export const sendMessage = ({ content, conversationId, accountId, websiteToken, contactId }) => {
  return {
    url: `/widget/conversations/${conversationId}/messages?website_token=${websiteToken}`,
    params: {
      message: {
        content,
        echo_id: generateEchoId(),
      },
      conversationId,
      accountId,
      contactId,
    },
  };
};

export const sendAttachment = ({ attachment, conversationId, accountId, websiteToken, contactId }) => {
  const { file } = attachment;

  const formData = new FormData();
  formData.append('attachments[]', file, file.name);
  formData.append('message[echo_id]', generateEchoId());
  formData.append('conversationId', conversationId);
  formData.append('contactId', contactId);
  formData.append('accountId', accountId);
  return {
    url: `/widget/conversations/${conversationId}/messages?website_token=${websiteToken}`,
    params: formData,
  };
};
