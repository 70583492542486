<template>
  <svg :width="size" :height="size" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path v-for="source in pathSource" :key="source" :d="source" fill="currentColor" />
  </svg>
</template>
<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
  },

  computed: {
    pathSource() {
      // To support icons with multiple paths
      const path = this.icons[`${this.icon}-${this.type}`];
      if (path.constructor === Array) {
        return path;
      }
      return [path];
    },
  },
};
</script>
