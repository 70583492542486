<template>
  <div class="widget-header-collapsed">
    <div class="widget-header-branding">
      <div>
        <div :class="getClassName">
          <span class="tw-novatalks-mr-1" v-html="title" />
        </div>
      </div>
      <font-awesome-icon @click="showToggleChat" class="widget-back" icon="fa-solid fa-window-minimize" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppConfigStore } from '@/stores/appConfig';

export default {
  name: 'ChatHeader',
  props: {
    avatarUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    showPopoutButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClassName() {
      return ['text-black-900', 'font-medium', 'text-base', 'flex', 'items-center']
        .map((x) => 'tw-novatalks-' + x)
        .join(' ');
    },
  },
  methods: {
    ...mapActions(useAppConfigStore, ['toggleUiFlags']),
    showToggleChat() {
      this.toggleUiFlags({ isShowChat: false });
      this.toggleUiFlags({ isShowChannels: true });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
#nova-talks-app {
  .widget-header-collapsed {
    display: flex;
    justify-content: space-between;
    padding: $space-two $space-medium;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 16px;
    .widget-header-branding {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      span {
        color: $color-heading;
      }
      path {
        color: $color-heading;
      }
    }

    .title {
      font-weight: $font-weight-medium;
    }
  }

  .widget-back {
    cursor: pointer;
    font-size: 22px;
  }
}
</style>
